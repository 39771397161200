import { useEffect } from 'react';
import useTracking from 'components/Globals/Analytics';
import { SECTIONS } from 'components/Globals/Analytics/constants';

function PwaTracker() {
  const track = useTracking();

  const handlePwaView = actionType => {
    track.actionCallback({
      section: SECTIONS.VIEW_PWA,
      meta: { actionType, id: actionType },
    });
  };

  const handleChange = event => {
    if (event.matches) {
      handlePwaView('transfer'); // NOTE: Trigger when entering standalone mode
    }
  };

  useEffect(() => {
    const matchStandalone = window.matchMedia('(display-mode: standalone)');

    if (!matchStandalone) {
      return null;
    }

    if (matchStandalone.matches) {
      handlePwaView('mount'); // NOTE: Trigger when already in standalone mode on mount
    }

    matchStandalone.addEventListener('change', handleChange);

    return () => {
      matchStandalone.removeEventListener('change', handleChange);
    };
  }, []);

  return null;
}

export default PwaTracker;
