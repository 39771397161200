import get from 'lodash/get';
import toArray from 'lodash/toArray';
import groupBy from 'lodash/groupBy';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';

import { PARENT_PROFESSION_SLUG, REPERTOIRE_SOURCE, futureRepertoireStatuses } from 'constants/consts';
import { SORT_OPTION_TYPES } from 'constants/index';

import { getOption } from 'utils/globals/app';

// TODO: we need to optimise below method
const groupRepertoireRecords = (repertoire, sortComposerAsc = true) => {
  const { orderedComposers, groupedByComposer } = repertoire?.reduce(
    (acc, item) => {
      const composer = item?.work?.creators?.find(creator => creator?.profession?.slug === 'composer');

      acc.orderedComposers.add(composer?.profile?.shortName);
      acc.groupedByComposer[composer?.profile?.id] = [...(acc.groupedByComposer?.[composer?.profile?.id] || []), item];

      return acc;
    },
    { orderedComposers: new Set(), groupedByComposer: {} },
  );

  const data = {};
  const workRoles = [];
  Object.keys(groupedByComposer).map(key => {
    const works = get(groupedByComposer, key);
    let composer = get(works, '[0].work.creators');
    composer = composer?.filter(creator => creator?.profession?.slug === 'composer');
    const groupedWorks = groupBy(works, 'work.id');
    const worksStructured = {};
    if (composer?.length) {
      Object.keys(groupedWorks).map(workId => {
        const roles = get(groupedWorks, workId);
        const workInfo = get(roles, '[0].work');
        const list = [];
        roles.map((role, pointer) => {
          if (role?.productionsTotal > 0 || futureRepertoireStatuses?.includes(role?.status)) {
            workRoles.push(`${workInfo?.id}_${pointer}`);
            list.push(role);
          }
          return list;
        });

        set(worksStructured, workId, {
          work: workInfo,
          data: toArray(list),
        });
        return worksStructured;
      });
      set(data, [composer?.[0]?.profile?.shortName], {
        composer,
        data: sortBy(toArray(worksStructured), r => r?.work?.name?.toUpperCase()),
      });
    }
    return data;
  });
  let sortedComposers = Array.from(orderedComposers)?.sort();

  if (!sortComposerAsc) {
    sortedComposers = sortedComposers.reverse();
  }

  const result = {
    composerNames: sortedComposers,
    workRoles,
    data,
  };

  return result;
};

const extractRepertoireDetails = (repertoireRecord, sortComposerAsc, limit) => {
  const [scheduleRep = [], futureRep = []] =
    repertoireRecord?.reduce(
      (result, rep) => {
        if (
          !futureRepertoireStatuses?.includes(rep?.status) &&
          (rep?.source === REPERTOIRE_SOURCE.USER || rep?.source === REPERTOIRE_SOURCE.PERFORMANCE)
        ) {
          result[0].push(rep);
        }
        if (futureRepertoireStatuses?.includes(rep?.status) && rep?.source === REPERTOIRE_SOURCE.USER) {
          result[1].push({ ...rep, isFuture: true });
        }

        return result;
      },
      [[], []],
    ) || [];

  const scheduledRepertoire = limit > 0 ? scheduleRep?.slice(0, limit) : scheduleRep;

  return {
    futureRepertoire: { ...groupRepertoireRecords(futureRep, sortComposerAsc), isFuture: true },
    scheduleRepertoire: groupRepertoireRecords(scheduledRepertoire, sortComposerAsc),
    scheduledRepertoireCount: scheduleRep?.length,
  };
};

// TODO: Remove this for casting view and modify transformRepertoireData
export const transformCastingViewRepertoireData = (response, props) =>
  extractRepertoireDetails(
    response?.data,
    props?.filters?.sort === SORT_OPTION_TYPES.COMPOSERS_ASC.value,
    props?.limit,
  );

export const transformRepertoireData = response => {
  const futureRepertoire = {};
  const scheduleRepertoire = {};
  let futureRepertoireCount = 0;
  let scheduleRepertoireCount = 0;

  // eslint-disable-next-line no-unused-expressions
  response?.data?.forEach(repertoire => {
    const composer = repertoire?.work?.creators?.filter(creator => creator?.profession?.slug === 'composer');
    const composerName = composer?.[0]?.profile?.shortName;
    const status = repertoire?.status;
    const workRoleName = repertoire?.workRole?.name;
    const workProfessionName = repertoire?.profession?.name;

    if (composerName) {
      const isFutureRepertoire =
        futureRepertoireStatuses.includes(status) && repertoire?.source === REPERTOIRE_SOURCE.USER;
      const isScheduleRepertoire =
        !futureRepertoireStatuses.includes(status) &&
        (repertoire?.source === REPERTOIRE_SOURCE.USER || repertoire?.source === REPERTOIRE_SOURCE.PERFORMANCE);

      if (isFutureRepertoire) {
        futureRepertoireCount += 1;
        futureRepertoire[composerName] = futureRepertoire[composerName] || { data: [], composer };
        futureRepertoire[composerName].data.push({
          id: repertoire?.id,
          name: repertoire?.work?.original_name,
          roles: workRoleName ? [{ roleName: workRoleName, status }] : [],
          professionNames: workProfessionName ? [{ professionName: workProfessionName, status }] : [],
        });
      } else if (isScheduleRepertoire) {
        scheduleRepertoireCount += 1;
        scheduleRepertoire[composerName] = scheduleRepertoire[composerName] || { data: [], composer };
        const existingWork = scheduleRepertoire[composerName].data.find(
          work => work.name === repertoire?.work?.original_name,
        );

        if (existingWork) {
          if (workRoleName) {
            const updatedWorkRoles = Array.isArray(existingWork.roles) ? [...existingWork.roles] : [];
            const isWorkRoleExist = updatedWorkRoles.some(item => item?.roleName === workRoleName);
            if (workRoleName && !isWorkRoleExist) {
              updatedWorkRoles.push({
                roleName: workRoleName,
                status,
              });
            }

            existingWork.roles = updatedWorkRoles;
          } else if (workProfessionName) {
            const updatedProfessionNames = Array.isArray(existingWork.professionNames)
              ? [...existingWork.professionNames]
              : [];
            const isProfessionExist = updatedProfessionNames.some(item => item?.professionName === workProfessionName);
            if (workProfessionName && !isProfessionExist) {
              updatedProfessionNames.push({
                professionName: workProfessionName,
                status,
              });
            }

            existingWork.professionNames = updatedProfessionNames;
          }
        } else {
          scheduleRepertoire[composerName].data.push({
            id: repertoire?.id,
            name: repertoire?.work?.original_name,
            roles: workRoleName ? [{ roleName: workRoleName, status }] : [],
            professionNames: workProfessionName ? [{ professionName: workProfessionName, status }] : [],
          });
        }
      }
    }
  });

  return { futureRepertoire, scheduleRepertoire, futureRepertoireCount, scheduleRepertoireCount };
};

export const transformedSuggestedData = response =>
  response.reduce((groupedData, obj) => {
    const key = Object.keys(obj)?.[0];
    const value = Object.values(obj)?.[0];

    return {
      ...groupedData,
      [key]: [...(groupedData[key] || []), { entity: value, entityType: key }],
    };
  }, {});

export const transformSplitByProfessionType = response => ({
  ...response,
  data: (response?.data || []).reduce(
    (acc, profession) => {
      if (profession?.parentProfession?.id) {
        if (profession?.parentProfession?.slug === PARENT_PROFESSION_SLUG.SINGER) {
          acc.voiceTypes.push(getOption(profession, 'name', undefined, undefined, true));
        } else if (profession?.parentProfession?.slug === PARENT_PROFESSION_SLUG.INSTRUMENTALIST) {
          acc.instrumentTypes.push(getOption(profession, 'name', undefined, undefined, true));
        }

        return acc;
      }

      acc.professions.push(getOption(profession, 'name', undefined, undefined, true));
      return acc;
    },
    { professions: [], voiceTypes: [], instrumentTypes: [] },
  ),
});

export const selectTrendingArtistProfessions = (response, limit) => {
  const { professions, voiceTypes, instrumentTypes } = response?.data || {};
  const sortedOptions = [...professions, ...voiceTypes, ...instrumentTypes]
    ?.filter(({ pro }) => pro >= 8)
    ?.sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
    ?.slice(0, limit);

  return sortedOptions;
};
