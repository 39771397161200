import { TP } from 'constants/index';
import { REG_HUBSPOT_FORM_IDS } from 'containers/Accounts/constants';
import { submitHubspotForm } from 'utils/formUtils';
import { i18n } from 'src/i18n';

export const errorMessageConverter = error => error?.responseText || error?.responseText?.message || error?.message;

export const registrationPayload = ({
  userData = {},
  step = 1,
  origin = 'registration-popup',
  originLink,
  popupSource,
  flowId,
  gift,
}) => ({
  step,
  entities: {
    user: {
      id: userData?.id,
      name: userData?.name,
    },
  },
  meta_data: {
    source: {
      origin,
      link: originLink,
      popupSource,
    },
    gift: { token: gift?.token, source: gift?.tokenType },
    language: userData?.preferredLanguage,
    first_name: userData?.firstName,
    last_name: userData?.lastName,
    contact: {
      email: userData?.email,
    },
  },
  flow_id: flowId,
});

export const profilePayload = ({ registrationData, payload, userData }) => ({
  user: { id: registrationData?.entities?.user?.id || userData?.id },
  firstName: registrationData?.meta_data?.first_name || userData?.firstName,
  lastName: registrationData?.meta_data?.last_name || userData?.lastName,
  isRegistration: true,
  ...payload,
});

export const trackHubspotForm = ({ hubspotPayload = [], userData, registrationData, isOrg, isAddNewOrg }) => {
  const isProduction = process.env.APP_ENV === 'production';
  const formId = REG_HUBSPOT_FORM_IDS({
    isProduction,
    step: registrationData?.step,
    flowId: registrationData?.flow_id,
    isOrg,
    isAddNewOrg,
  });

  let userPayload = [];
  if (userData) {
    userPayload = [
      { name: 'user_id__database_', value: userData?.id },
      { name: 'email', value: userData?.email },
    ];
  }

  if (formId) {
    submitHubspotForm(process.env.HUBSPOT_REGISTER_KEY, formId, {
      fields: [
        ...userPayload,
        { name: 'hs_language', value: i18n?.language },
        { name: 'registration_form_step', value: registrationData?.step },
        { name: 'registrationfirststepcreatedate', value: registrationData?.created_at },
        ...hubspotPayload,
      ],
    });
  }
};
