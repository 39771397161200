import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import head from 'lodash/head';
import last from 'lodash/last';
import join from 'lodash/join';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';
import partition from 'lodash/partition';
import reduce from 'lodash/reduce';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import findLast from 'lodash/findLast';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';

import { convertMapToArray } from 'utils';
import {
  getProductionName,
  getRouteWithSlug,
  getRoutePartWithSlug,
  groupMembersByWork,
  getEntityAndTypeFromContributor as originalGetEntityAndTypeFromContributor,
} from 'utils/common';
import { createDate } from 'utils/date';

import { ENSEMBLES_TYPE_NAMES, SINGER_PROFESSION_TYPE_ID } from 'constants/consts';
import {
  TP,
  PROFESSION_IDS,
  ENTITY_MAIN_TABS,
  ENTITY_TYPE,
  TAGS,
  TAG_TYPES,
  VIEW_MODES_VALUE,
  PARTNER_TYPE,
  VALIDATION_STATUS,
  PERFORMANCE_DATE_MODE_TYPES,
  DATE_FORMATS,
  CONTRIBUTORS,
  SEASON_AGGREGATION_TYPES,
  ENSEMBLE_RENDER_ORDER,
  ORGANIZATION_TYPE_IDS,
  SORT_OPTION_VALUES,
  SEASON_TYPES,
  ENTITY_META_DETA_TYPES,
  ENTITY_TYPE_PREFIX,
  REGEX_CONSTANTS,
} from 'constants/index';
import route from 'constants/routes';

import { getComposerFromCreators, getComposerName, getComposersFromCreators } from 'utils/composer';

const VIDEO_ACTION_BUTTONS = {
  POSTER: 'poster',
  TRAILER: 'trailer',
  WATCH_OPTIONS: 'watch_options',
  WATCH_ON_CUETV: 'watch_on_cuetv',
  LIVE_NOW: 'live_now',
  STREAM_OPTIONS: 'stream_options',
};

export const getPerformanceTags = performance => {
  const tags = [
    {
      label: `${TP}.FN_PERFORMACE_TYPE_WORLD_PREMIERE`,
      isValid: performance?.isWorldPremiere,
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.WORLD_PREMIERE,
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_NATIONAL_PREMIERE`,
      isValid: performance?.isPremiere,
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.NATIONAL_PREMIERE,
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_NEW_PRODUCTION`,
      isValid: performance?.isNewProduction ?? false,
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.NEW_PRODUCTION,
    },
    {
      label: `${TP}.PERFORMANCE_CANCELLED`,
      isValid: performance?.isCancelled,
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.IS_CANCELLED,
    },
    // {
    //   label: `${TP}.FN_RECITAL`,
    //   isValid: false,
    //   type: TAGS,
    // },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_MATINEE`,
      isValid: performance?.isMatinee,
      type: TAG_TYPES.DATE,
      tag: TAGS.IS_MATINEE,
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_TOUR`,
      isValid: performance?.isTour,
      type: TAG_TYPES.DATE,
      tag: TAGS.IS_TOUR,
    },
  ];

  return tags?.filter(tag => tag?.isValid);
};

export const getProductionTags = (production, types, unique) => {
  let workTags = [];

  if (types?.includes(TAG_TYPES.WORK) || !types?.length) {
    map(production?.productionWorks, tag => {
      if (tag?.work?.workType?.name) {
        if (unique) {
          if (!workTags.includes(tag?.work?.workType?.name)) {
            workTags.push(tag?.work?.workType?.name);
          }
        } else {
          workTags.push(tag?.work?.workType?.name);
        }
      }
    });
    workTags = workTags?.filter(item => item?.length > 0)?.join(', ');
  }

  const tags = [
    {
      label: `${TP}.FN_PERFORMACE_TYPE_WORLD_PREMIERE`,
      isValid: !!production?.performances?.find(item => item.isWorldPremiere),
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.WORLD_PREMIERE,
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_NATIONAL_PREMIERE`,
      isValid: !!production?.performances?.find(item => item.isPremiere),
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.NATIONAL_PREMIERE,
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_NEW_PRODUCTION`,
      isValid: production?.isNewProduction ?? false,
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.NEW_PRODUCTION,
    },
    {
      label: `${TP}.PERFORMANCE_CANCELLED`,
      isValid: production?.isCancelled,
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.IS_CANCELLED,
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_FESTIVAL`,
      isValid: production?.contributions?.some(
        contributor => contributor?.organization?.organizationType?.id === ORGANIZATION_TYPE_IDS.FESTIVAL,
      ),
      type: TAG_TYPES.FESTIVAL,
      tag: TAGS.IS_FESTIVAL,
    },
    {
      label: workTags,
      isValid: !!production?.productionWorks?.find(item => item?.work?.workType?.name),
      type: TAG_TYPES.WORK,
      tag: TAGS.WORK,
    },
    // {
    //   label: `${TP}.FN_RECITAL`,
    //   isValid: false,
    //   type: TAGS,
    // },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_MATINEE`,
      isValid: !!production?.performances?.find(item => item.isMatinee),
      type: TAG_TYPES.DATE,
      tag: TAGS.IS_MATINEE,
      legend: 'm',
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_TOUR`,
      isValid: !!production?.performances?.find(item => item.isTour),
      type: TAG_TYPES.DATE,
      tag: TAGS.IS_TOUR,
      legend: 't',
    },
  ];

  if (types?.length > 0) {
    return tags.filter(tag => tag?.isValid && types?.includes(tag?.type));
  }

  return tags?.filter(tag => tag?.isValid);
};

export const getProductionStubTags = production => {
  // const workTags = production?.works?.map(tag => ({
  //   workType: tag?.workType,
  //   stageType: tag?.stageType,
  //   concat: `${tag?.workType} ${tag?.stageType}`,
  // }));

  // const uniqueWorkTags = [...new Map(workTags.map(item => [item.concat, item])).values()];

  const tags = [
    {
      label: `${TP}.FN_PERFORMACE_TYPE_WORLD_PREMIERE`,
      isValid: !!production?.performances?.find(item => item.isWorldPremiere),
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.WORLD_PREMIERE,
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_NATIONAL_PREMIERE`,
      isValid: !!production?.performances?.find(item => item.isPremiere),
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.NATIONAL_PREMIERE,
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_NEW_PRODUCTION`,
      isValid: production?.isNewProduction ?? false,
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.NEW_PRODUCTION,
    },
    {
      label: `${TP}.PERFORMANCE_CANCELLED`,
      isValid: production?.isCancelled,
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.IS_CANCELLED,
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_FESTIVAL`,
      isValid: production?.festivals?.length > 0,
      type: TAG_TYPES.FESTIVAL,
      tag: TAGS.IS_FESTIVAL,
    },
    {
      label: `${TP}.FN_REVIVAL`,
      isValid: !!production?.isRevival,
      type: TAG_TYPES.PRODUCTION,
      tag: TAGS.REVIVAL,
    },
    // {
    //   label: `${TP}.FN_RECITAL`,
    //   isValid: false,
    //   type: TAGS,
    // },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_MATINEE`,
      isValid: !!production?.performances?.find(item => item.isMatinee),
      type: TAG_TYPES.DATE,
      tag: TAGS.IS_MATINEE,
      legend: 'm',
    },
    {
      label: `${TP}.FN_PERFORMACE_TYPE_TOUR`,
      isValid: !!production?.performances?.find(item => item.isTour),
      type: TAG_TYPES.DATE,
      tag: TAGS.IS_TOUR,
      legend: 't',
    },
  ];

  return {
    // works: uniqueWorkTags, TODO: design team needs to come up with new design
    mainTags: tags?.filter(tag => tag?.isValid),
  };
};

export const getProductionProducedBy = (production, viewMode) => {
  const producedByCompany = groupBy(production?.companies, company => company?.partnerType);
  const producedByFestival = groupBy(production?.festivals, festival => festival?.partnerType);

  const producedByOthers = production?.others || [];
  let name = null;
  let city = null;
  let id = null;
  let country = null;
  let producedBy = null;

  const mainProducer = producedByCompany[PARTNER_TYPE.PRODUCER]?.[0] || producedByFestival[PARTNER_TYPE.PRODUCER]?.[0];

  if (mainProducer) {
    name = mainProducer?.name;
    id = mainProducer?.id;
    city = mainProducer?.city?.name;
    country = mainProducer?.country?.name;
  } else if (producedByOthers?.[0]?.length) {
    const item = head(producedByOthers);
    name = item?.name;
    id = item?.id;
  }

  if (viewMode === VIEW_MODES_VALUE.DETAILED) {
    producedBy = {
      name,
      city,
      country,
      id,
      company: producedByCompany[PARTNER_TYPE.CO_PRODUCER],
      festival: producedByFestival[PARTNER_TYPE.CO_PRODUCER],
      other: producedByOthers?.[0] || {},
    };

    return producedBy;
  }

  producedBy = {
    name,
    city,
    country,
    id,
    company: [producedByCompany[PARTNER_TYPE.CO_PRODUCER]?.[0]]?.filter(Boolean),
    festival: [producedByFestival[PARTNER_TYPE.CO_PRODUCER]?.[0]]?.filter(Boolean),
    other: producedByOthers?.[0] || {},
  };

  return producedBy;
};

// TODO: This is checking a production - should be renamed to isPastProduction
export const isPastPerformance = production => {
  const today = createDate();
  const futureDate = createDate(production?.maxDate).endOf('day');

  const pastPerformance = today?.isAfter(futureDate);
  return pastPerformance;
};

export const getMinMaxProductionDates = production => {
  const productionMinDate = production?.minDate;
  const productionMaxDate = production?.maxDate;

  if (productionMinDate && productionMaxDate) {
    return {
      minDate: productionMinDate,
      maxDate: productionMaxDate,
    };
  }

  const allDates = orderBy(production?.performances, datesList => new Date(datesList?.startDate), ['asc']);
  if (allDates?.length === 0) {
    return {};
  }

  const startDate = allDates?.find(item => item.startDate)?.startDate;
  const endDate = findLast(allDates, item => item.startDate)?.startDate;

  return {
    minDate: startDate,
    maxDate: endDate,
  };
};

// TODO: Maybe improve getProductionDateParts to accomodate season (Junaid/Ishant)
export const getProductionDateParts = (production, { separators = {}, shortMonth = false } = {}) => {
  const { yearSeparator = '/', dateSeparator = ' - ', monthSeparator = ' - ' } = separators;
  const monthFormat = shortMonth ? 'MMM' : 'MMMM';
  const dayMonthFormat = shortMonth ? 'DD MMM' : 'DD MMMM';
  const { minDate: productionMinDate, maxDate: productionMaxDate } = getMinMaxProductionDates(production);
  let years = null;

  const isProductionDatesInSameYear =
    new Date(productionMinDate).getFullYear() === new Date(productionMaxDate).getFullYear();

  const lastTwoDigit = new Date(productionMaxDate)
    .getFullYear()
    ?.toString()
    ?.slice(-2);

  if (productionMinDate) {
    years = isProductionDatesInSameYear
      ? new Date(productionMinDate).getFullYear()
      : `${new Date(productionMinDate).getFullYear()}${yearSeparator}${lastTwoDigit || ''}`;
  } else {
    years = createDate(productionMaxDate).format('YYYY');
  }

  const isProductionDatesInSameMonth =
    new Date(productionMinDate).getMonth() === new Date(productionMaxDate).getMonth();

  const isProductionDatesOnSameDay = new Date(productionMinDate).getDate() === new Date(productionMaxDate).getDate();

  const months = isProductionDatesInSameMonth
    ? createDate(new Date(productionMinDate)).format(monthFormat)
    : `${createDate(productionMinDate).format(monthFormat)}${monthSeparator}${createDate(productionMaxDate).format(
        monthFormat,
      )}`;

  let dates = '';

  if (isProductionDatesInSameMonth) {
    if (isProductionDatesOnSameDay) {
      dates = createDate(productionMinDate).format(dayMonthFormat);
    } else {
      dates = `${createDate(productionMinDate).format('DD')}${dateSeparator}${createDate(productionMaxDate).format(
        dayMonthFormat,
      )}`;
    }
  } else {
    dates = `${createDate(productionMinDate).format(dayMonthFormat)}${dateSeparator}${createDate(
      productionMaxDate,
    ).format(dayMonthFormat)}`;
  }

  return {
    dates,
    years,
    months,
    startDate: createDate(productionMinDate).format('DD MMM YYYY'),
    endDate: createDate(productionMaxDate).format('DD MMM YYYY'),
  };
};

export const getPerformanceDateParts = production => {
  const productionMinDate = production?.startDate || production?.minDate;
  const years = new Date(productionMinDate).getFullYear();
  const months = createDate(new Date(productionMinDate)).format('MMM');
  const dates = createDate(productionMinDate).format('ddd');
  const day = createDate(productionMinDate).format('DD');

  return {
    dates,
    years,
    months,
    day,
  };
};

export const convertTimeFormat = time => {
  const parts = time?.split(':');
  // eslint-disable-next-line radix
  const hours = parseInt(parts?.[0]);
  // eslint-disable-next-line radix
  const minutes = parseInt(parts?.[1]);
  if (isNaN(hours) && isNaN(minutes)) {
    return null;
  }
  const hourStr = hours > 0 ? `${hours}h ` : '';
  const minuteStr = `${minutes}mins`;
  return hourStr + minuteStr;
};

export const useProductionSummary = production => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const specialCrew = {
    director: {
      title: `${TP}.FN_STAGE_DIRECTOR`,
      filter: item => item?.profession?.id === PROFESSION_IDS.STAGE_DIRECTOR,
      linkProps: item => ({
        href: route.DYNAMIC_ID_ROUTE_WITH_ACTION(route.ARTISTS),
        as: getRouteWithSlug(route.ARTISTS, item?.profile),
      }),
    },
    conductor: {
      title: `${TP}.m_CONDUCTOR`,
      filter: item => item?.profession?.id === PROFESSION_IDS.CONDUCTOR,
      linkProps: item => ({
        href: route.DYNAMIC_ID_ROUTE_WITH_ACTION(route.ARTISTS),
        as: getRouteWithSlug(route.ARTISTS, item?.profile),
      }),
    },
  };

  // todo:cast and crew structure different from review and media
  const crew = production?.crew;
  const casts = production?.casts;
  const primaryCrew = useMemo(
    () =>
      Object.keys(specialCrew)
        ?.map(key => {
          const specialCrewMember = specialCrew?.[key];

          return {
            key,
            title: specialCrewMember?.title,
            linkProps: specialCrewMember?.linkProps,
            member:
              crew?.find(item => specialCrewMember.filter(item)) || casts?.find(item => specialCrewMember.filter(item)),
          };
        })
        ?.filter(team => team.member),
    [crew, specialCrew, casts],
  );

  return {
    name: production?.name,
    poster: production?.poster?.file?.urls,
    dateRange: useMemo(() => getProductionDateParts(production), [production]),
    crew,
    primaryCrew,
    other: production?.other,
    venues: production?.venues,
    venuesCityGrouped: groupBy(production?.venues, venue => venue?.city?.name),
    works: useMemo(() => getProductionWorks(production?.productionWorks), [production?.productionWorks]),
    companies: production?.companies,
    festivals: production?.festivals,
    languages: production?.languages,
    surtitles: production?.surtitles,
    directors: production?.directors,
    conductors: production?.conductors,
    photoCount: production?.numbers?.imageCount,
    videoCount: production?.numbers?.videoCount,
    productionPageLink: getRouteWithSlug(route.PRODUCTIONS, production),
    period: production?.periode,
    subtitles: production?.surtitles,
    isArchived: production?.isArchived,
    isCancelled: production?.isCancelled,
    isNewProduction: production?.isNewProduction,
    isProgramNotAnnounced: production?.isProgramNotAnnounced,
    productionDates: production?.productionDates,
    isRevival: production?.isRevival,
    slug: production?.slug,
    revivedProduction: production?.revivedProduction,
    isProductionVerified: production?.validationStatus?.id === VALIDATION_STATUS.APPROVED,
    id: production?.id,
    ticketUrl: production?.tickets?.[0],
    website: production?.website,
    performances: filterDigitalVODDates(production),
    productionStageTag: production?.productionWorkTypes?.[0]?.workType?.name,
    ...(production?.duration && { duration: convertTimeFormat(production?.duration) }),
    productionLevelTags: production?.productionToTags?.map(item => ({
      prodTag: item?.productionTag?.name,
      stageTag: production?.productionWorkTypes?.[0]?.workType?.name,
    })),
  };
};

export const isFutureRepertoire = repertoire =>
  repertoire?.status?.toLowerCase() === 'prepared' || repertoire?.status?.toLowerCase() === 'unprepared';

export const getBreadcrumbs = ({ entity, tab }) => {
  const { title: activeTabName, key: activeTabValue } = tab || {};

  let customBreadcrumbs = [
    { title: `${TP}.m_HOME` },
    { path: `${route.PRODUCTIONS}/`, title: `${TP}.m_PERF` },
    { path: `${getRouteWithSlug(`${route.PRODUCTIONS}`, entity)}/`, title: getProductionName(entity) },
  ];

  if (activeTabValue !== ENTITY_MAIN_TABS.HOME) {
    customBreadcrumbs = [
      ...customBreadcrumbs,
      {
        path: `${route.PRODUCTIONS}/${getRoutePartWithSlug(entity)}/${activeTabValue}/`,
        title: activeTabName,
      },
    ];
  }

  return customBreadcrumbs;
};

export const useGroupedCastCrew = (work, date) => {
  let { cast, crew, other } = work || {};

  const [otherList, instrumentList] = partition(
    other,
    item => item?.workRoleType?.id !== PROFESSION_IDS.INSTRUMENTATION,
  );

  const [primaryCrew, otherCrew] = partition(crew, item =>
    [PROFESSION_IDS.STAGE_DIRECTOR, PROFESSION_IDS.CONDUCTOR].includes(item?.profession?.id),
  );

  if (date) {
    const getCastCrewForPerformance = list =>
      list?.filter(item => {
        if (date) {
          return item?.performances?.some(performance => {
            const performanceDate = createDate(performance, DATE_FORMATS.FULL_DATE);
            const dateObj = createDate(date, DATE_FORMATS.FULL_DATE);
            return dateObj.isSame(performanceDate, 'day');
          });
        }
        return true;
      });

    cast = getCastCrewForPerformance(cast);
    crew = getCastCrewForPerformance(crew);
    other = getCastCrewForPerformance(otherList);
  }

  const groupedCast = useMemo(() => {
    const { ...rest } = groupBy(cast, member => member?.workRole?.original_name || member?.workRole?.name || 'ignore');

    return rest;
  }, [cast]);
  const groupedPrimaryCrew = useMemo(() => {
    const { ...rest } = groupBy(
      primaryCrew,
      member => member?.profession?.name || member?.professionReference || 'ignore',
    );

    return rest;
  }, [primaryCrew]);
  const groupedOtherCrew = useMemo(() => {
    const { ...rest } = groupBy(
      otherCrew,
      member => member?.profession?.name || member?.professionReference || 'ignore',
    );

    return rest;
  }, [otherCrew]);
  const groupedOther = useMemo(() => {
    const { ...rest } = groupBy(otherList, member => member?.workRoleReference || 'ignore');

    return rest;
  }, [otherList]);

  const groupedInstrumentList = useMemo(() => {
    const { ...rest } = groupBy(instrumentList, member => member?.workRoleReference || member?.credit || 'ignore');

    return rest;
  }, [instrumentList]);

  return {
    cast: [...convertMapToArray(groupedCast, 'role'), ...convertMapToArray(groupedOther, 'role')],
    crew: convertMapToArray(groupedOtherCrew, 'role'),
    primaryCrew: convertMapToArray(groupedPrimaryCrew, 'role'),
    instrumentCast: convertMapToArray(groupedInstrumentList, 'role'),
  };
};

export const useGroupedEnsembles = (production, work) => {
  const groupedEnsembles = useMemo(() => {
    const ensembles =
      production?.contributions?.filter(item => {
        if (item?.contributionType === CONTRIBUTORS.ENSEMBLE) {
          if (work) {
            return item?.productionWork?.id === work?.id;
          }

          return true;
        }

        return false;
      }) || {};
    const { ...rest } = groupBy(ensembles, ensemble => ensemble?.organization?.organizationType?.name || 'ignore');

    return rest;
  }, [production, work]);

  return convertMapToArray(groupedEnsembles, 'type');
};

export const useEnsemblesList = (ensembles, navigate) => {
  const ensembleList = useMemo(() => {
    const ensembleTypes = {
      [ENSEMBLES_TYPE_NAMES.ORCHESTRA]: {
        translation: `${TP}.m_FN_ORCHESTRA`,
      },
      [ENSEMBLES_TYPE_NAMES.CHOIR]: {
        translation: `${TP}.m_FN_CHORUS`,
      },
      [ENSEMBLES_TYPE_NAMES.BALLET]: {
        translation: `${TP}.FN_BALLET_OR_GROUP`,
      },
      [ENSEMBLES_TYPE_NAMES.OTHER]: {
        translation: `${TP}.FN_CASTING_TOOL_OTHER_ROLES`,
      },
    };

    return ensembles?.map(({ type, data }) => ({
      title: ensembleTypes[type]?.translation,
      data: data?.map(item => {
        const { entity, entityType } = getEntityAndTypeFromContributor(item);
        const isLinkable = entity?.id !== -1 && entity?.validationStatus?.id === VALIDATION_STATUS.APPROVED;
        const linkProps = isLinkable
          ? navigate.getLinkProps({
              entityType,
              entity,
            })
          : null;

        return {
          title: entity?.name,
          dates: item?.dates,
          linkProps,
        };
      }),
    }));
  }, [ensembles, navigate]);

  return ensembleList || [];
};

export const getProductionWorks = productionWorks => {
  const works = productionWorks?.map(productionWork => {
    const { work, workExcerptReference, productionWorkToWorkTypes, id } = productionWork || {};
    return {
      id: work?.id,
      productionWorkId: id,
      originalName: work?.original_name,
      name: work?.name,
      workExcerptReference,
      composer:
        work?.composer?.name ??
        getComposersFromCreators(work?.creators)
          ?.map(composer => composer?.profile?.name)
          .join(', '),
      productionTypeName: work?.productionType?.name,
      workStageTypes: productionWorkToWorkTypes?.map(item => ({
        workType: item?.workType?.name,
        stagingType: item?.stagingType?.name,
      })),
      slug: work?.slug,
      ...work,
    };
  });

  return works;
};

export const useProducerDetails = (production, navigate) => {
  const { producers, coProducers } = useMemo(() => {
    const contributors = production?.contributions?.reduce(
      (acc, contributor) => {
        if ([CONTRIBUTORS.PRODUCER, CONTRIBUTORS.CO_PRODUCER].includes(contributor?.contributionType)) {
          const { entity, entityType } = getEntityAndTypeFromContributor(contributor);
          const isLinkable = entity?.id !== -1 && entity?.validationStatus?.id === VALIDATION_STATUS.APPROVED;
          const linkProps = isLinkable
            ? navigate.getLinkProps({
                entityType,
                entity,
              })
            : null;

          const type =
            contributor?.contributionType === CONTRIBUTORS.PRODUCER ? `${TP}.FN_PRODUCER` : `${TP}.FN_CO_PRODUCER`;

          const data = {
            type,
            value: entity?.name,
            href: linkProps?.href,
            as: linkProps?.as,
            image: entity?.image || entity?.logo,
            id: entity?.id,
            city: entity?.city?.name,
            country: entity?.country?.name,
            entityType,
          };

          if (CONTRIBUTORS.PRODUCER === contributor?.contributionType) {
            return {
              ...acc,
              producers: [...acc?.producers, data],
            };
          }

          return {
            ...acc,
            coProducers: [...acc?.coProducers, data],
          };
        }

        return acc;
      },
      {
        producers: [],
        coProducers: [],
      },
    );

    return {
      producers: convertMapToArray(groupBy(contributors?.producers, 'type'), 'type'),
      coProducers: convertMapToArray(groupBy(contributors?.coProducers, 'type'), 'type'),
    };
  }, [production?.contributions, navigate]);

  return {
    producers,
    coProducers,
  };
};

export const useProductionSlugDetails = production => {
  const specialCrew = {
    director: {
      title: `${TP}.FN_STAGE_DIRECTOR`,
      filter: item => item?.profession?.id === PROFESSION_IDS.STAGE_DIRECTOR,
      linkProps: item => ({
        href: route.DYNAMIC_ID_ROUTE_WITH_ACTION(route.ARTISTS),
        as: getRouteWithSlug(route.ARTISTS, item?.profile),
      }),
    },
    conductor: {
      title: `${TP}.m_CONDUCTOR`,
      filter: item => item?.profession?.id === PROFESSION_IDS.CONDUCTOR,
      linkProps: item => ({
        href: route.DYNAMIC_ID_ROUTE_WITH_ACTION(route.ARTISTS),
        as: getRouteWithSlug(route.ARTISTS, item?.profile),
      }),
    },
  };

  // todo:cast and crew structure different from review and media
  const crew = production?.crew;
  const casts = production?.casts;
  const primaryCrew = useMemo(
    () =>
      Object.keys(specialCrew)
        ?.map(key => {
          const specialCrewMember = specialCrew?.[key];

          return {
            key,
            title: specialCrewMember?.title,
            linkProps: specialCrewMember?.linkProps,
            member:
              crew?.find(item => specialCrewMember.filter(item)) || casts?.find(item => specialCrewMember.filter(item)),
          };
        })
        ?.filter(team => team.member),
    [crew, specialCrew, casts],
  );

  return {
    name: production?.name,
    dateRange: useMemo(() => getProductionDateParts(production), [production]),
    crew,
    primaryCrew,
    works: useMemo(() => getProductionWorks(production?.productionWorks), [production?.productionWorks]),
  };
};

export const getVenueCities = production =>
  production?.venues?.map(venue => ({
    city: venue?.city?.name,
    country: venue?.country?.name,
    name: venue?.name,
  }));

const concatProfileRoles = profiles => {
  const profileRoles = profiles?.reduce((all, profile, index) => {
    const comma = index !== profiles?.length - 1 ? ', ' : '';
    return all.concat(`${profile?.profile?.name || profile?.profileReference}${comma}`);
  }, '');
  return profileRoles;
};

export const getProductionCastCrew = (production, viewMode) => {
  const allDirectors = production?.crew?.filter(type => type?.profession?.id === PROFESSION_IDS.STAGE_DIRECTOR);
  const allConductors = production?.crew?.filter(type => type?.profession?.id === PROFESSION_IDS.CONDUCTOR);

  const specialCrew = {
    director: {
      title: `${TP}.FN_STAGE_DIRECTOR`,
      filter: item => item?.profession?.id === PROFESSION_IDS.STAGE_DIRECTOR,
      linkProps: item => ({
        href: route.DYNAMIC_ID_ROUTE_WITH_ACTION(route.ARTISTS),
        as: getRouteWithSlug(route.ARTISTS, item?.profile),
      }),
    },
    conductor: {
      title: `${TP}.m_CONDUCTOR`,
      filter: item => item?.profession?.id === PROFESSION_IDS.CONDUCTOR,
      linkProps: item => ({
        href: route.DYNAMIC_ID_ROUTE_WITH_ACTION(route.ARTISTS),
        as: getRouteWithSlug(route.ARTISTS, item?.profile),
      }),
    },
  };

  const crew = production?.crew;
  const casts = production?.casts;
  const primaryCrew = Object.keys(specialCrew)
    ?.map(key => {
      const specialCrewMember = specialCrew?.[key];

      return {
        key,
        title: specialCrewMember?.title,
        linkProps: specialCrewMember?.linkProps,
        member:
          crew?.find(item => specialCrewMember.filter(item)) || casts?.find(item => specialCrewMember.filter(item)),
      };
    })
    ?.filter(team => team.member);

  if (viewMode === VIEW_MODES_VALUE.DETAILED) {
    const groupedWorks = groupMembersByWork(production);
    const totalCastCrewLength = groupedWorks?.reduce(
      (total, item) => total + item?.crew?.length + item?.cast?.length + item?.other?.length,
      0,
    );

    const castCrewOtherProfiles = groupedWorks?.reduce((prevState, work) => {
      const castCrewInfo = {
        ...prevState,
        [work?.name]: {
          ...useGroupedCastCrew(work),
          crew: useGroupedCastCrew(work)?.crew?.map(items => ({
            role: items?.role,
            data: items?.data?.map(item => concatProfileRoles([item])),
          })),
          cast: useGroupedCastCrew(work)?.cast?.map(items => ({
            role: items?.role,
            data: items?.data?.map(item => concatProfileRoles([item])),
          })),
        },
      };

      return castCrewInfo;
    }, {});

    return {
      data: { ...castCrewOtherProfiles },
      total: totalCastCrewLength,
    };
  }

  if (viewMode === VIEW_MODES_VALUE.SUMMARY) {
    const concatDirector = {
      key: 'director',
      title: `${TP}.FN_STAGE_DIRECTOR`,
      name: concatProfileRoles(allDirectors),
    };
    const concatConductor = {
      key: 'conductor',
      title: `${TP}.m_CONDUCTOR`,
      name: concatProfileRoles(allConductors),
    };

    return [concatDirector, concatConductor];
  }

  return primaryCrew;
};

export const getPerformanceCrew = production => {
  const allDirectors = production?.crew?.filter(type => type?.profession?.id === PROFESSION_IDS.STAGE_DIRECTOR);
  const allConductors = production?.crew?.filter(type => type?.profession?.id === PROFESSION_IDS.CONDUCTOR);
  // Day View
  const stageDirectors = allDirectors
    ?.slice(0, 2)
    ?.map(profile => ({ key: 'director', title: `${TP}.FN_STAGE_DIRECTOR`, name: profile?.profile?.name }));
  const conductors = allConductors
    ?.slice(0, 2)
    ?.map(profile => ({ key: 'conductor', title: `${TP}.m_CONDUCTOR`, name: profile?.profile?.name }));
  return [...stageDirectors, ...conductors];
};

export const removeDuplicatesItems = list => {
  const uniqueIds = [];
  const arrList = list?.length > 0 ? [...list] : [];
  const unique = arrList?.filter(element => {
    const isDuplicate = uniqueIds.includes(element.id);
    if (!isDuplicate) {
      uniqueIds.push(element.id);
      return true;
    }
    return false;
  });
  return unique || [];
};

export const castCrewDates = dates => {
  if (!dates) {
    return null;
  }

  return join(
    values(
      mapValues(
        groupBy(
          uniqBy(
            reduce(
              orderBy(dates, date => createDate(date, DATE_FORMATS.FULL_DATE).format(DATE_FORMATS.FULL_DATE)),
              (acc, date) => {
                const lastDate = last(acc);
                if (lastDate) {
                  const day = createDate(date);
                  const year = day.format('YYYY');
                  const month = day.format('MMM');
                  const lastDateYear = lastDate?.year;
                  const lastDateMonth = lastDate?.month;

                  let label = '';

                  if (year !== lastDateYear) {
                    label += year;
                  }

                  if (month !== lastDateMonth) {
                    if (label.length > 0) {
                      label += ' ';
                    }
                    label += month;
                  }

                  if (label.length > 0) {
                    label += ' ';
                  }
                  label += day.format('DD');

                  acc.push({ label, year, month, date });
                  return acc;
                }
                const day = createDate(date);
                return [
                  {
                    year: day.format('YYYY'),
                    month: day.format('MMM'),
                    label: day.format('YYYY MMM DD'),
                    date,
                  },
                ];
              },
              [],
            ),
            'date',
          ),
          ({ month }) => month,
        ),
        datesObj =>
          join(
            map(datesObj, ({ label }) => label),
            ', ',
          ),
      ),
    ),
    '; ',
  );
};

export const getProductionTitle = (production, hideWorkTranslation = false) => {
  const customName = production?.name || '';

  if (customName) {
    return customName;
  }

  const originalWorkName = production?.productionWorks?.[0]?.work?.original_name;
  const workTranslation = production?.productionWorks?.[0]?.work?.name;

  let workName = '';

  if (originalWorkName?.length > 0) {
    workName = originalWorkName;
  }

  if (!hideWorkTranslation && workTranslation?.length > 0 && originalWorkName !== workTranslation) {
    if (workName?.length > 0) {
      workName += ' - ';
      workName += ` (${workTranslation})`;
    } else {
      workName = `${workTranslation}`;
    }
  }

  return workName;
};

// TODO: Refactor this function
export const groupPerformanceByLocation = (productions, isPerformanceView) => {
  const venuesWithDates = {};
  const monthsWithDates = {};
  const tempStorage = {}; // insert only unique dates FB-21880

  map(productions, production => {
    const dt = createDate(production?.startDate, DATE_FORMATS.FULL_DATE);
    monthsWithDates[dt.format(DATE_FORMATS.FULL_MONTH_YEAR)] = [
      ...(monthsWithDates[dt.format(DATE_FORMATS.FULL_MONTH_YEAR)] || []),
      production?.startDate,
    ];
    const performanceData = isPerformanceView && { production: production?.production };
    map(production?.venues, venue => {
      if (!venuesWithDates[venue?.id]) {
        venuesWithDates[venue?.id] = {
          venue,
          ...performanceData,
          dates: {
            [dt.format(DATE_FORMATS.FULL_MONTH_YEAR)]: [{ ...venue, startDate: production.startDate }],
          },
        };
      } else if (!venuesWithDates[venue?.id]?.dates[dt.format(DATE_FORMATS.FULL_MONTH_YEAR)]) {
        venuesWithDates[venue?.id].dates[dt.format(DATE_FORMATS.FULL_MONTH_YEAR)] = [
          { ...venue, startDate: production.startDate, ...performanceData },
        ];
      } else if (!tempStorage[`${venue?.id}-${production?.startDate}`]) {
        venuesWithDates[venue?.id].dates[dt.format(DATE_FORMATS.FULL_MONTH_YEAR)].push({
          ...venue,
          ...performanceData,
          startDate: production.startDate,
        });
      }
      const datesInSortedOrder = Object.keys(venuesWithDates[venue?.id]?.dates)?.sort((a, b) => {
        const [monthA, yearA] = a?.split(' ');
        const [monthB, yearB] = b?.split(' ');
        return createDate(`${yearA}-${monthA}-${'01'}`).diff(createDate(`${yearB}-${monthB}-${'01'}`));
      });

      venuesWithDates[venue?.id].sortedDates = datesInSortedOrder;
      tempStorage[`${venue?.id}-${production?.startDate}`] = true;
    });
  });

  return {
    venuesWithDates: Object.values(venuesWithDates),
    monthsWithDates,
  };
};

export const getEntityLatestUpcomingPerformance = (production, entityId, entityType = ENTITY_TYPE.ARTIST) => {
  if (!production?.id) {
    return null;
  }

  let performancesDates = [];

  if (entityType === ENTITY_TYPE.PRODUCTION) {
    performancesDates = production?.performances?.map(item => item?.startDate);
  } else {
    performancesDates = production?.contributions?.find(item => {
      if (entityType === ENTITY_TYPE.ORGANIZATION) {
        return item?.organization?.id === Number(entityId);
      }
      return item?.profile?.id === Number(entityId);
    })?.performances;
  }

  const latestUpcomingDate = performancesDates?.find(item => {
    const currentDt = createDate();
    const itemDt = createDate(item);
    if (itemDt?.isAfter(currentDt)) {
      return item;
    }
    return null;
  });

  return production?.performances?.find(item => item?.startDate === latestUpcomingDate?.toString());
};

export const getEntityPerformedDates = (production, entityId, entityType = ENTITY_TYPE.ARTIST) => {
  const contributor = production?.contributions?.find(item => {
    if (entityType === ENTITY_TYPE.ORGANIZATION) {
      return item?.organization?.id === Number(entityId);
    }
    return item?.profile?.id === Number(entityId);
  });

  const uniqDates = new Set();

  map([contributor], item => {
    map(item?.performances, date => {
      uniqDates.add(date);
    });
  });

  const dates = [];
  uniqDates.forEach(date => {
    const value = production?.performances.find(item => item?.startDate === date?.toString());
    if (value) {
      dates.push(value);
    }
  });

  const groupByVenues = groupBy(dates, ({ venue }) => venue?.id);

  const venuesAndDatesGroup = {};
  map(Object.keys(groupByVenues), venueId => {
    let finalVenue = production?.venues?.find(venue => venue?.id === Number(venueId));

    const groupByDates = groupByVenues[venueId]?.reduce((acc, item) => {
      if (!item?.startDate) {
        return acc;
      }

      const dt = createDate(item?.startDate).format(DATE_FORMATS.FULL_MONTH_YEAR);
      if (!acc[dt]) {
        acc[dt] = [
          {
            ...(item?.venue || {}),
            city: finalVenue?.city,
            country: finalVenue?.country,
            startDate: item.startDate,
          },
        ];
      } else {
        acc[dt].push({
          ...(item?.venue || {}),
          city: finalVenue?.city,
          country: finalVenue?.country,
          startDate: item.startDate,
        });
      }

      return acc;
    }, {});

    // TODO: Remove this when we have a proper venue id
    if (!finalVenue) {
      finalVenue = groupByVenues[venueId]?.[0]?.venue;
    }

    venuesAndDatesGroup[venueId] = {
      dates: groupByDates,
      venue: finalVenue,
    };
  });

  return {
    total: production?.performances?.length,
    groupedData: Object.values(venuesAndDatesGroup),
    artistPerformedDates: uniqDates?.size,
    isClash: contributor?.clash,
  };
};

export const getProductionMeta = production => {
  const productionTitle = getProductionTitle(production) || '';
  const creator =
    getComposerName(getComposerFromCreators(production?.productionWorks?.[0]?.work?.creators)?.profile, true) || '';
  const { minDate, maxDate } = getMinMaxProductionDates(production) || {};
  const { name: producer = '' } = getProductionProducer(production) || {};
  const { costumeDesigner = '' } = getProductionCostumeDesigner(production) || {};
  return {
    title: productionTitle,
    creator,
    ...(minDate && maxDate && { year: `(${minDate?.split('-')?.[0]}${maxDate && `/${maxDate?.split('-')?.[0]}`})` }),
    producer,
    costumeDesigner,
  };
};

export const getProfileRoles = (production, profile) => {
  if (!profile?.id) {
    return null;
  }

  const casts = [...(production?.casts || []), ...(production?.crew || []), ...(production?.other || [])].filter(
    cast => cast?.profile?.id === profile?.id,
  );

  if (casts.length === 0) {
    return null;
  }

  return uniqBy(
    casts?.map(cast => {
      let role = cast?.profession?.name;

      if (cast?.professionReference) {
        role = cast?.professionReference;
      }

      if (cast?.workRoleReference) {
        role = cast?.workRoleReference;
      }

      if (cast?.workRole) {
        role = cast?.workRole?.original_name || cast?.workRole?.name;
      }

      const type = cast?.profession?.parentProfession?.id === SINGER_PROFESSION_TYPE_ID ? cast?.profession?.name : null;
      const key = `${role}${type ? `-${type}` : ''}`;

      return {
        role,
        type,
        key,
      };
    }),
    'key',
  );
};

export const getAllWorksAndComposers = production => {
  const worksComposers = production?.productionWorks?.map(work => ({
    workName: work?.work?.name,
    composer: getComposerName(getComposerFromCreators(work?.work?.creators)?.profile),
  }));

  return worksComposers;
};

export const getVenuesWithStage = (venues, dates) => {
  const stagesAdded = [];
  const stagesPerVenue = dates?.reduce((acc, date) => {
    if (!date?.venue?.id || !date?.stage || stagesAdded.includes(date?.stage?.id)) {
      return acc;
    }

    if (acc[date?.venue?.id]) {
      acc[date?.venue?.id].push(date?.stage);
    } else {
      acc[date?.venue?.id] = [date?.stage];
    }
    stagesAdded.push(date?.stage?.id);
    return acc;
  }, {});

  const venuesWithStage = venues?.reduce((acc, venue) => {
    const stages = stagesPerVenue[venue?.id];
    if (stages) {
      // eslint-disable-next-line no-unused-expressions
      stages?.forEach(stage => {
        acc[stage?.id] = { ...venue, stage };
      });
    } else {
      acc[venue?.id] = venue;
    }
    return acc;
  }, {});

  return venuesWithStage;
};

export const filterDigitalVODDates = production =>
  production?.performances?.filter(
    date => date?.mode !== PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO && typeof date?.startDate === 'string',
  );

export const filterDigitalDates = production =>
  production?.performances?.filter(
    date =>
      ![PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO, PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM].includes(date?.mode) &&
      typeof date?.startDate === 'string',
  );

export const hasOnGoingPerformance = (dates, maxDate) => {
  const today = createDate();
  const maxDayDate = createDate(maxDate);

  if (maxDayDate.isBefore(today, 'day')) {
    return false;
  }

  return dates?.some(({ date }) => {
    const currentDate = createDate(date);
    const isSameMonth = currentDate.isSame(today, 'month');
    const isBeforeMaxDate = currentDate.isBefore(maxDayDate) || currentDate.isSame(maxDayDate, 'day');
    return isSameMonth && isBeforeMaxDate;
  });
};

export const getProductionWorkTypeTag = production =>
  production?.productionWorkTypes?.[0]?.workType ||
  production?.productionWorks?.[0]?.productionWorkToWorkTypes?.[0]?.workType ||
  production?.productionWorks?.[0]?.work?.workType;

export const getProductionDatesGroupedByVenues = (production, dateFormat = DATE_FORMATS.FULL_MONTH_YEAR) => {
  const venuesWithStage = getVenuesWithStage(production?.venues, production?.performances);

  return orderBy(
    Object?.values(
      production?.performances?.reduce((acc, date) => {
        if (date.mode === PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO || typeof date?.startDate !== 'string') {
          return acc;
        }

        const venueId = date?.venue?.id;
        const stageId = date?.stage?.id;
        const isLivestream = date?.mode === PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM;

        const aggregationKey =
          stageId || venueId || (isLivestream ? PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM : 'no-venue');
        const venue = venuesWithStage[aggregationKey] || date?.venue;

        if (acc[aggregationKey]) {
          acc[aggregationKey].dates.push(date);
        } else {
          acc[aggregationKey] = { venue, dates: [date], isLivestream };
        }

        return acc;
      }, {}),
    )?.map(({ venue, dates, isLivestream }) => ({
      venue,
      isLivestream,
      dates: groupBy(
        orderBy(dates, date => createDate(date?.startDate, DATE_FORMATS.FULL_DATE).format(DATE_FORMATS.FULL_DATE)),
        date => createDate(date?.startDate, DATE_FORMATS.FULL_DATE).format(dateFormat),
      ),
      ungroupedDates: dates,
    })),
    [data => data?.ungroupedDates?.[0].startDate, 'venue.city.id', 'isLivestream'],
  );
};

export const getProductionActivePerformancesDates = performances => {
  const rawActivePerformancesDates = performances?.reduce((acc, date) => {
    if (!date?.isCancelled) {
      acc.push(date?.startDate);
    }
    return acc;
  }, []);
  return rawActivePerformancesDates?.sort((first, second) => (createDate(first).isBefore(createDate(second)) ? -1 : 1));
};

// TODO: Restructure this function - Current implementation is hack to avoid cyclic dependency
export const getEntityAndTypeFromContributor = originalGetEntityAndTypeFromContributor;

export const useGetCastCrew = ({ production, navigate }) => {
  const { directors, conductors, cast, restCrew } = production?.contributions?.reduce(
    (acc, contributor) => {
      const { entity, entityType } = getEntityAndTypeFromContributor(contributor);
      if (contributor?.contributionType === CONTRIBUTORS.CREW) {
        if (contributor?.profession?.id === PROFESSION_IDS.STAGE_DIRECTOR) {
          acc.directors.push({
            ...contributor,
            linkProps: navigate.getLinkProps({ entity, entityType }),
          });
        } else if (contributor?.profession?.id === PROFESSION_IDS.CONDUCTOR) {
          acc.conductors.push({
            ...contributor,
            linkProps: navigate.getLinkProps({ entity, entityType }),
          });
        } else {
          acc.restCrew.push({
            ...contributor,
            linkProps: navigate.getLinkProps({ entity, entityType }),
          });
        }
      } else if (contributor?.contributionType === CONTRIBUTORS.CAST) {
        acc.cast.push({
          ...contributor,
          linkProps: navigate.getLinkProps({ entity, entityType }),
        });
      }

      return acc;
    },
    { directors: [], conductors: [], cast: [], restCrew: [] },
  );

  const primaryCrew = [...directors, ...conductors];

  return {
    cast,
    primaryCrew,
    restCrew,
    directors,
    conductors,
  };
};

export const getProductionProducer = (production, allProducers = false) => {
  if (allProducers) {
    const producers = production?.contributions?.filter(item => item?.contributionType === CONTRIBUTORS.PRODUCER);
    return producers;
  }

  const producer = production?.contributions?.find(item => item?.contributionType === CONTRIBUTORS.PRODUCER);
  return producer;
};

export const getProductionEnsembles = production => {
  const ensembles = uniqBy(
    production?.contributions?.filter(item => item?.contributionType === CONTRIBUTORS.ENSEMBLE),
    item => item?.organization?.id,
  );

  return {
    ensembles,
  };
};

export const getProductionCostumeDesigner = production => {
  const costumeDesigner = production?.contributions?.filter(
    item => item?.contributionType === CONTRIBUTORS.CREW && item?.profession?.id === PROFESSION_IDS.COSTUME_DESIGNER,
  );
  return {
    costumeDesigner,
  };
};

export const getSeasonStartEndDates = (seasonType, seasonId) => {
  let dt = createDate();

  if (seasonId) {
    dt = dt.year(seasonId);
  }

  if (seasonType === SEASON_AGGREGATION_TYPES.CALENDAR.value) {
    return {
      startDate: dt.startOf('year'),
      endDate: dt.endOf('year'),
    };
  }
  // NOTE: season starts in September 1st and ends in 30th June
  const seasonStartDate = dt.set('month', '7').endOf('month');
  const seasonEndDate = dt.set('month', '5').endOf('month');

  return {
    startDate: seasonStartDate,
    endDate: seasonEndDate,
  };
};

export const shouldShowYear = ({ itemIndex, venueIndex, currentYear, venuesWithDates }) => {
  const sortedDates = venuesWithDates?.[venueIndex]?.sortedDates;
  const previousVenueDates = venuesWithDates?.[venueIndex - 1]?.sortedDates;
  const yearOfLastDateGroup = sortedDates?.[itemIndex - 1]?.split?.(' ')?.[1];

  if (itemIndex === 0) {
    const currentVenue = venuesWithDates?.[venueIndex]?.venue;
    const yearOfLastVenue = previousVenueDates?.[previousVenueDates?.length - 1]?.split?.(' ')?.[1];
    return isEmpty(currentVenue) ? true : currentYear !== yearOfLastVenue;
  }
  return currentYear !== yearOfLastDateGroup;
};

export const getProductionTitleWorkNames = production => {
  const workNames = production?.productionWorks?.map(work => work?.work?.original_name);
  const customName = production?.name;
  return {
    workNames,
    ...(customName && { customName }),
  };
};

export const getProductionCredits = (production, options = {}) => {
  const totalPhysicalPerformanceDates = filterDigitalDates(production)?.length || 0;
  const { workId, entityId, entityType, performanceDate } = options;

  const getContributorInfo = contributor => {
    const { organization, profile } = contributor;
    let contributorEntity;
    let contributorEntityType;
    let isLinkable = false;

    if (organization?.id) {
      contributorEntity = organization;
      contributorEntityType = ENTITY_TYPE.ORGANIZATION;
      isLinkable = organization?.id !== -1 && organization?.validationStatus?.id === VALIDATION_STATUS.APPROVED;
    } else if (profile?.id) {
      contributorEntity = profile;
      contributorEntityType = ENTITY_TYPE.ARTIST;
      isLinkable = profile?.id !== -1 && profile?.validationStatus?.id === VALIDATION_STATUS.APPROVED;
    }

    return {
      identifier: `${contributorEntity?.id}-${contributorEntityType}`,
      entity: contributorEntity,
      entityType: contributorEntityType,
      isLinkable,
      contributionId: contributor?.id,
      performances: contributor?.performances,
      isCover: contributor?.isCover,
      clash: contributor?.clash,
      showPerformanceDates: totalPhysicalPerformanceDates !== contributor?.performances?.length,
      productionWorks: contributor?.productionWork?.work ? [contributor.productionWork.work] : [],
    };
  };

  const updateContributorMap = (contributorMap, contributor) => {
    const { identifier, ...data } = contributor;
    const updatedMap = contributorMap || new Map();

    if (updatedMap.has(identifier)) {
      const matchedContributor = updatedMap.get(identifier);
      updatedMap.set(identifier, {
        ...matchedContributor,
        performances: [...(matchedContributor?.performances || []), ...(data?.performances || [])],
        productionWorks: [...(matchedContributor?.productionWorks || []), ...(data?.productionWorks || [])],
      });
    } else {
      updatedMap.set(identifier, data);
    }

    return updatedMap;
  };

  const updateEntityRoles = (entityRoles, contribution) => {
    const updatedMap = entityRoles || new Set();
    if (contribution) {
      updatedMap.add(contribution);
    }

    return updatedMap;
  };

  const getCastOrderScore = cast => {
    if (cast.performer && cast.workRole) {
      const nonStandardShift = cast.workRole?.is_standard ? 0 : 1000;

      return 1000 + cast.workRole?.roleOrder + nonStandardShift;
    }
    if (cast.performer) {
      return 2000;
    }
    return 3000;
  };

  const getCrewOrderScore = crew => {
    if (!crew.performer && crew.profession) {
      return 3000;
    }
    return 6000;
  };

  const contributorMapToArray = group => ({
    title: group?.title,
    subTitle: group?.subTitle,
    hasCurrentEntity: group?.hasCurrentEntity,
    data: [...group?.data?.values()],
  });

  const getCastCrewList = groupedList =>
    Object.values(groupedList)
      .sort((a, b) => a.order - b.order)
      .reduce((acc, group) => {
        acc.push(contributorMapToArray(group));
        return acc;
      }, []);

  const getCastWorkRoleName = workRole => {
    let roleName = workRole?.original_name;

    if (workRole?.name !== workRole?.original_name && workRole?.name) {
      roleName += ` (${workRole?.name})`;
    }

    return roleName;
  };

  const isContributorInPerformance = (contributor, date) => {
    if (!date || [CONTRIBUTORS.PRODUCER, CONTRIBUTORS.CO_PRODUCER].includes(contributor?.contributionType)) {
      return true;
    }

    return contributor?.performances.includes(date);
  };

  const credits = production?.contributions?.reduce(
    (acc, contributor) => {
      if (
        (workId !== -1 &&
          workId &&
          contributor?.productionWork?.work?.id !== workId &&
          [CONTRIBUTORS.CAST, CONTRIBUTORS.CREW, CONTRIBUTORS.ENSEMBLE].includes(contributor?.contributionType)) ||
        (workId === -1 && contributor?.productionWork?.work?.id)
      ) {
        return acc;
      }

      const isPartOfPerformance = isContributorInPerformance(contributor, performanceDate);
      if (!isPartOfPerformance) {
        return acc;
      }

      let data = getContributorInfo(contributor);
      const isEntityContribution =
        entityId && entityType && data?.entity?.id === entityId && data?.entityType === entityType;
      data = { ...data, isCurrentEntity: isEntityContribution };

      switch (contributor?.contributionType) {
        case CONTRIBUTORS.CAST: {
          const castGroup = [contributor?.profession?.parentProfession?.id, contributor?.profession?.id].includes(
            PROFESSION_IDS.INSTRUMENTATION,
          )
            ? 'instrumentalists'
            : 'cast';
          const castType = contributor?.workRole?.slug || contributor?.credit;
          const order = getCastOrderScore(contributor);
          const workRoleName = isEntityContribution ? getCastWorkRoleName(contributor?.workRole) : null;

          return {
            ...acc,
            ...(isEntityContribution && {
              entityRoles: updateEntityRoles(acc?.entityRoles, workRoleName),
            }),
            [castGroup]: {
              ...acc?.[castGroup],
              [castType]: {
                title: contributor?.workRole?.original_name ?? contributor?.credit,
                subTitle:
                  contributor?.workRole?.name !== contributor?.workRole?.original_name
                    ? contributor?.workRole?.name
                    : null,
                order: Math.min(order, acc?.[castGroup]?.[castType]?.order || order),
                hasCurrentEntity: isEntityContribution || acc?.[castGroup]?.[castType]?.hasCurrentEntity || false,
                data: updateContributorMap(acc?.[castGroup]?.[castType]?.data, data),
              },
            },
          };
        }
        case CONTRIBUTORS.CREW: {
          if (!contributor?.profession?.id) {
            return acc;
          }

          if (contributor?.profession?.id === PROFESSION_IDS.STAGE_DIRECTOR) {
            return {
              ...acc,
              ...(isEntityContribution && {
                entityRoles: updateEntityRoles(acc?.entityRoles, contributor?.profession?.name),
              }),
              directors: {
                title: contributor?.profession?.name ?? contributor?.credit,
                hasCurrentEntity: isEntityContribution || acc?.directors?.hasCurrentEntity || false,
                data: updateContributorMap(acc?.directors?.data, data),
              },
            };
          }

          if (contributor?.profession?.id === PROFESSION_IDS.CONDUCTOR) {
            return {
              ...acc,
              ...(isEntityContribution && {
                entityRoles: updateEntityRoles(acc?.entityRoles, contributor?.profession?.name),
              }),
              conductors: {
                title: contributor?.profession?.name,
                hasCurrentEntity: isEntityContribution || acc?.conductors?.hasCurrentEntity || false,
                data: updateContributorMap(acc?.conductors?.data, data),
              },
            };
          }

          const order = getCrewOrderScore(contributor);
          const crewType = contributor?.profession?.slug;

          return {
            ...acc,
            ...(isEntityContribution && {
              entityRoles: updateEntityRoles(acc?.entityRoles, contributor?.profession?.name),
            }),
            crew: {
              ...acc?.crew,
              [crewType]: {
                title: contributor?.profession?.name,
                hasCurrentEntity: isEntityContribution || acc?.crew?.[crewType]?.hasCurrentEntity || false,
                order: Math.min(order, acc?.crew?.[crewType]?.order || order),
                data: updateContributorMap(acc?.crew?.[crewType]?.data, data),
              },
            },
          };
        }
        case CONTRIBUTORS.PRODUCER: {
          return {
            ...acc,
            // NOTE: We currently don't want to show producer in the entity roles list
            // ...(isEntityContribution && {
            //   entityRoles: updateEntityRoles(acc?.entityRoles, `${TP}.FN_PRODUCER`),
            // }),
            producers: {
              title: `${TP}.FN_PRODUCER`,
              hasCurrentEntity: isEntityContribution || acc?.producers?.hasCurrentEntity || false,
              data: updateContributorMap(acc?.producers?.data, data),
            },
          };
        }
        case CONTRIBUTORS.CO_PRODUCER: {
          return {
            ...acc,
            ...(isEntityContribution && {
              entityRoles: updateEntityRoles(acc?.entityRoles, `${TP}.FN_CO_PRODUCER`),
            }),
            coProducers: {
              title: `${TP}.FN_CO_PRODUCER`,
              hasCurrentEntity: isEntityContribution || acc?.coProducers?.hasCurrentEntity || false,
              data: updateContributorMap(acc?.coProducers?.data, data),
            },
          };
        }
        case CONTRIBUTORS.ENSEMBLE: {
          data = { ...data, ensembleType: contributor?.ensembleType };
          const { slug: ensembleTypeSlug, name: ensembleTypeName } = data?.ensembleType || {};
          return {
            ...acc,
            ...(isEntityContribution && {
              entityRoles: updateEntityRoles(acc?.entityRoles, data?.ensembleType?.name),
            }),
            ensembles: {
              ...acc.ensembles,
              [ensembleTypeSlug]: {
                title: ensembleTypeName,
                hasCurrentEntity: isEntityContribution || acc?.ensembles?.[ensembleTypeSlug]?.hasCurrentEntity || false,
                data: updateContributorMap(acc?.ensembles?.[ensembleTypeSlug]?.data, data),
              },
            },
          };
        }
        default:
          return acc;
      }
    },
    {
      producers: null,
      coProducers: null,
      conductors: null,
      directors: null,
      cast: null,
      instrumentalists: null,
      crew: null,
      ensembles: null,
      entityRoles: null,
    },
  );

  return {
    ...(credits?.entityRoles && {
      entityRoles: contributorMapToArray({ data: credits.entityRoles })?.data,
    }),
    ...(credits?.producers && { producers: contributorMapToArray(credits.producers) }),
    ...(credits?.coProducers && { coProducers: contributorMapToArray(credits.coProducers) }),
    ...(credits?.conductors && { conductors: contributorMapToArray(credits.conductors) }),
    ...(credits?.directors && { directors: contributorMapToArray(credits.directors) }),
    ...(credits?.ensembles && {
      ensembles: ENSEMBLE_RENDER_ORDER.reduce((acc, ensemble) => {
        if (credits.ensembles[ensemble.type]) {
          acc.push(contributorMapToArray({ title: ensemble?.label, data: credits.ensembles[ensemble.type]?.data }));
        }

        return acc;
      }, []),
    }),
    ...(credits?.crew && {
      crew: getCastCrewList(credits.crew),
    }),
    ...(credits?.cast && {
      cast: getCastCrewList(credits.cast),
    }),
    ...(credits?.instrumentalists && {
      instrumentalists: getCastCrewList(credits.instrumentalists),
    }),
  };
};

export const getProductionTitles = production => {
  const { id, name: customTitle, productionWorks } = production || {};
  const getUniqWorkComposers = composers => {
    const workComposerMap =
      composers?.reduce((acc, { profile }) => {
        const composer = {
          entity: {
            id: profile?.id,
            name: getComposerName(profile),
          },
          entityType: ENTITY_TYPE.ARTIST, // TODO: Change this to variable if we want to use it for entity type organization
        };

        acc.set(`${composer?.entity?.id}_${composer?.entityType}`, composer);
        return acc;
      }, new Map()) || new Map();

    return [...workComposerMap?.values()];
  };

  const getWorkComposers = work =>
    work?.creators?.filter(creator => creator?.profession?.id === PROFESSION_IDS.COMPOSER);

  if (customTitle) {
    const composerNames = getUniqWorkComposers(flatten(productionWorks?.map(({ work }) => getWorkComposers(work))));

    return [
      {
        title: customTitle,
        isCustomTitle: true,
        entityId: id,
        entityType: ENTITY_TYPE.PRODUCTION,
        composers: [...new Set(composerNames)],
      },
    ];
  }

  return productionWorks?.map(({ work }) => ({
    title: work?.original_name,
    translation: work?.original_name !== work?.name ? work?.name : null,
    isCustomTitle: false,
    entityId: work?.id,
    entityType: ENTITY_TYPE.WORK,
    composers: getUniqWorkComposers(getWorkComposers(work)),
  }));
};

export const getEntitySeasonType = ({ entityType, entity }) => {
  let type = SEASON_TYPES.CALENDAR_YEAR;

  if (entityType === ENTITY_TYPE.ORGANIZATION) {
    type =
      entity?.metadata?.find(({ name }) => name === ENTITY_META_DETA_TYPES.SEASON)?.value || SEASON_TYPES.CALENDAR_YEAR;
  }

  return type;
};

export const getBaseSeasonFilters = ({
  entityType,
  entity,
  upcoming = false,
  sort = SORT_OPTION_VALUES.YEAR_DESC,
} = {}) => {
  const today = createDate();

  return {
    ...(upcoming
      ? {
          date_from: today.format(DATE_FORMATS.FULL_DATE),
          sort: SORT_OPTION_VALUES.YEAR_ASC,
        }
      : {
          date_to: today.subtract(1, 'day').format(DATE_FORMATS.FULL_DATE),
          date_max: today.subtract(1, 'day').format(DATE_FORMATS.FULL_DATE),
          sort,
        }),
    ...(entityType === ENTITY_TYPE.ARTIST && {
      ctx_entity: `a${entity?.id}`,
      include_virtual_contributors: true,
    }),
    ...(entityType === ENTITY_TYPE.ORGANIZATION && {
      ctx_entity: `o${entity?.id}`,
      include_virtual_contributors: true,
    }),
  };
};

export function getActiveProductionContributor(production, entityType, entityId) {
  const profileIdPrefix = ENTITY_TYPE_PREFIX[entityType];
  const matchedContributor = production?.contributions?.find(
    el => el.contributorId === `${profileIdPrefix}${entityId}`,
  );

  return matchedContributor;
}

export const showEntityRedMask = ({ entity, entityType, production }) => {
  const isRedMask = production?.contributions?.find(contributor => {
    const { entity: contributorEntity } = getEntityAndTypeFromContributor(contributor);
    return contributorEntity?.id === entity?.id;
  })?.isRedMasked;

  if ([ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType)) {
    return {
      show: true,
      icon: isRedMask ? 'red_mask' : 'grey_mask',
    };
  }

  return {
    show: false,
    icon: null,
  };
};

export const getWatchOptionDetails = production => {
  const today = createDate().startOf('day');
  let isFree = false;
  let isLive = false;
  let hasLiveStream = false;
  let hasCueTvMedia = false;

  const productionPerformances = production?.performances || [];

  productionPerformances.forEach(({ mode, isCancelled, attributes, startDate }) => {
    if (
      [PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM, PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO].includes(mode) &&
      !isCancelled
    ) {
      if (mode === PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM) {
        hasLiveStream = true;
      }

      (attributes || []).forEach(attribute => {
        if (attribute?.paymentType === 'free') {
          isFree = true;
        }

        if (attribute?.url?.match(REGEX_CONSTANTS.CUETV_URL)) {
          hasCueTvMedia = true;
        }
      });

      if (
        startDate &&
        createDate(startDate)
          .startOf('day')
          .isSame(today)
      ) {
        isLive = true;
      }
    }
  });

  let type = `${TP}.FN_WATCH_OPTIONS`;
  let event = VIDEO_ACTION_BUTTONS.WATCH_OPTIONS;

  if (isLive) {
    type = `${TP}.FN_LIVE_NOW`;
    event = VIDEO_ACTION_BUTTONS.LIVE_NOW;
  }
  if (hasLiveStream) {
    type = `${TP}.FN_STREAM_OPTIONS`;
    event = VIDEO_ACTION_BUTTONS.STREAM_OPTIONS;
  }
  if (hasCueTvMedia) {
    type = `${TP}.FN_WATCH_ON_CUETV`;
    event = VIDEO_ACTION_BUTTONS.WATCH_ON_CUETV;
  }

  return {
    ctaLabel: type,
    ctaEvent: event,
    isLive,
    isFree,
  };
};
