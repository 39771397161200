import { TP } from 'constants/index';

export const ISO_TRANSLATION_KEY_MAPPING = {
  en: `${TP}.EN_LANGUAGE_TRANSLATION`,
  de: `${TP}.DE_LANGUAGE_TRANSLATION`,
  it: `${TP}.IT_LANGUAGE_TRANSLATION`,
  fr: `${TP}.FR_LANGUAGE_TRANSLATION`,
  es: `${TP}.ES_LANGUAGE_TRANSLATION`,
  ca: `${TP}.CA_LANGUAGE_TRANSLATION`,
  cs: `${TP}.CS_LANGUAGE_TRANSLATION`,
  da: `${TP}.DA_LANGUAGE_TRANSLATION`,
  et: `${TP}.ET_LANGUAGE_TRANSLATION`,
  eu: `${TP}.EU_LANGUAGE_TRANSLATION`,
  ga: `${TP}.GA_LANGUAGE_TRANSLATION`,
  lv: `${TP}.LV_LANGUAGE_TRANSLATION`,
  lt: `${TP}.LT_LANGUAGE_TRANSLATION`,
  hu: `${TP}.HU_LANGUAGE_TRANSLATION`,
  nl: `${TP}.NL_LANGUAGE_TRANSLATION`,
  no: `${TP}.NO_LANGUAGE_TRANSLATION`,
  pl: `${TP}.PL_LANGUAGE_TRANSLATION`,
  pt: `${TP}.PT_LANGUAGE_TRANSLATION`,
  ro: `${TP}.RO_LANGUAGE_TRANSLATION`,
  sk: `${TP}.SK_LANGUAGE_TRANSLATION`,
  sl: `${TP}.SL_LANGUAGE_TRANSLATION`,
  fi: `${TP}.FI_LANGUAGE_TRANSLATION`,
  sv: `${TP}.SV_LANGUAGE_TRANSLATION`,
  mt: `${TP}.MT_LANGUAGE_TRANSLATION`,
  is: `${TP}.IS_LANGUAGE_TRANSLATION`,
  el: `${TP}.EL_LANGUAGE_TRANSLATION`,
  bg: `${TP}.BG_LANGUAGE_TRANSLATION`,
  ru: `${TP}.RU_LANGUAGE_TRANSLATION`,
  zh: `${TP}.ZH_LANGUAGE_TRANSLATION`,
  ja: `${TP}.JA_LANGUAGE_TRANSLATION`,
  ko: `${TP}.KO_LANGUAGE_TRANSLATION`,
};

export const primaryLanguageCodes = [
  {
    display_name: 'English',
    iso: 'en',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.en,
  },
  {
    display_name: 'Deutsch',
    iso: 'de',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.de,
  },
  {
    display_name: 'Italiano',
    iso: 'it',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.it,
  },
  {
    display_name: 'Français',
    iso: 'fr',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.fr,
  },
  {
    display_name: 'Español',
    iso: 'es',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.es,
  },
];

export const allLanguageCodes = [
  {
    display_name: 'Català',
    iso: 'ca',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.ca,
  },
  {
    display_name: 'český',
    iso: 'cs',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.cs,
  },
  {
    display_name: 'Dansk',
    iso: 'da',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.da,
  },
  {
    display_name: 'Deutsch',
    iso: 'de',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.de,
  },
  {
    display_name: 'Eesti',
    iso: 'et',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.et,
  },
  {
    display_name: 'English',
    iso: 'en',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.en,
  },
  {
    display_name: 'Español',
    iso: 'es',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.es,
  },
  {
    display_name: 'Euskara',
    iso: 'eu',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.eu,
  },
  {
    display_name: 'Français',
    iso: 'fr',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.fr,
  },
  {
    display_name: 'Gaeilge',
    iso: 'ga',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.ga,
  },
  {
    display_name: 'Italiano',
    iso: 'it',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.it,
  },
  {
    display_name: 'Latviešu',
    iso: 'lv',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.lv,
  },
  {
    display_name: 'Lietuvių',
    iso: 'lt',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.lt,
  },
  {
    display_name: 'Magyar',
    iso: 'hu',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.hu,
  },
  {
    display_name: 'Nederlands',
    iso: 'nl',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.nl,
  },
  {
    display_name: 'Norsk',
    iso: 'no',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.no,
    datetimeLocale: 'nb',
  },
  {
    display_name: 'Polski',
    iso: 'pl',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.pl,
  },
  {
    display_name: 'Português',
    iso: 'pt',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.pt,
  },
  {
    display_name: 'Română',
    iso: 'ro',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.ro,
  },
  {
    display_name: 'slovenský',
    iso: 'sk',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.sk,
  },
  {
    display_name: 'Slovenčina',
    iso: 'sl',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.sl,
  },
  {
    display_name: 'Suomi',
    iso: 'fi',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.fi,
  },
  {
    display_name: 'Svenska',
    iso: 'sv',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.sv,
  },
  {
    display_name: 'bil-Malti',
    iso: 'mt',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.mt,
  },
  {
    display_name: 'Íslenska',
    iso: 'is',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.is,
  },
  {
    display_name: 'Ελληνικά',
    iso: 'el',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.el,
  },
  {
    display_name: 'Български',
    iso: 'bg',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.bg,
  },
  {
    display_name: 'Русский',
    iso: 'ru',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.ru,
  },
  {
    display_name: '中文',
    iso: 'zh',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.zh,
  },
  {
    display_name: '日本語',
    iso: 'ja',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.ja,
  },
  {
    display_name: '한국어',
    iso: 'ko',
    translation_name: ISO_TRANSLATION_KEY_MAPPING.ko,
  },
];
