import shuffle from 'lodash/shuffle';
import omit from 'lodash/omit';
import { getList, getWholeList } from 'utils/API';
import { getLanguage } from 'utils/queriesUtil';
import { VALIDATION_STATUS } from 'constants/consts';
import { CONTRIBUTORS, ENTITY_TYPE, SORT_OPTION_TYPES, AGGREGATION_TYPES } from 'constants/index';
import { transformEntity } from 'utils/globals';
import { transformGetRandomisedOrderedList } from 'containers/Globals/transformers';
import { getQuery, postQuery, deleteQuery, transformQueryFn, updateQuery } from 'utils/globals/queries';

import { transformGetCustomerLogos } from './transformers';

const ENDPOINTS = {
  STATIC: {
    ORGANIZATIONS: 'organizations',
    ORGANIZATIONS_TYPES: 'organizations/types',
    ORGANIZATIONS_DESIGNATIONS: 'organizations/designations',
  },
  DYNAMIC: {
    ORGANIZATION_ABOUT: id => `organizations/${id}/translations`,
    ORGANIZATION_RELATIONS: orgId => `organizations/${orgId}/relations`,
    ORGANIZATION_EMPLOYEES: orgId => `organizations/${orgId}/employees`,
    ORGANIZATION_CONTACTS: orgId => `organizations/${orgId}/contacts`,
    ORGANIZATION_WORK_TYPES: orgId => `organizations/${orgId}/workTypes`,
    ORGANIZATION_WORK_TYPES_DELETE: ({ orgId, workId }) => `organizations/${orgId}/workTypes/${workId}`,
    ORGANIZATION_ADDRESSES: orgId => `organizations/${orgId}/addresses`,
    ORGANIZATION_STAGES: orgId => `organizations/${orgId}/stages`,
    ORGANIZATION_STAGE_DELETE: ({ orgId, stageId }) => `organizations/${orgId}/stages/${stageId}`,
    ORGANIZATION_CERTAIN_EMPLOYEE: (orgIdOrParams, empId) => {
      const orgId = typeof orgIdOrParams === 'object' ? orgIdOrParams?.orgId : orgIdOrParams;
      const employeeId = empId || orgIdOrParams?.empId;
      return employeeId ? `organizations/${orgId}/employees/${employeeId}` : `organizations/${orgId}/employees`;
    },
  },
};

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
  cacheTime: 10000,
};

const fetchOrganizations = ({ filters, limit = 10, page }, cookies) => {
  const params = {
    queryParams: filters,
    pagination: {
      limit,
      page,
    },
  };

  return getList(ENDPOINTS.STATIC.ORGANIZATIONS, params, cookies).then(response => response?.data);
};

const fetchOrganization = ({ id, showApprovedHidden = false, asEdit = false }, cookies) => {
  const validationStatus = [VALIDATION_STATUS.APPROVED, VALIDATION_STATUS.PENDING];

  if (showApprovedHidden) {
    validationStatus.push(VALIDATION_STATUS.APPROVED_HIDDEN);
  }

  const params = {
    queryParams: {
      id,
      validation_status: validationStatus,
      ...(asEdit && { as_edit: true }),
    },
    pagination: {
      limit: 1,
      page: 1,
    },
  };

  return getList(ENDPOINTS.STATIC.ORGANIZATIONS, params, cookies).then(response =>
    transformEntity(response?.data?.data?.[0], ENTITY_TYPE.ORGANIZATION),
  );
};

const fetchProductionRentalContacts = ({ entity }, cookies) => {
  const producerId = entity?.contributions?.find(({ contributionType }) => contributionType === CONTRIBUTORS.PRODUCER)
    ?.organization?.id;

  if (producerId === -1) {
    return null;
  }

  const params = {
    queryParams: { is_rental_contact: true },
  };

  return getWholeList(`organizations/${producerId}/employees`, params, cookies).then(response => response?.data);
};

const fetchTranslations = ({ id }, cookies) =>
  getList(ENDPOINTS.DYNAMIC.ORGANIZATION_ABOUT(id), {}, cookies).then(response => response?.data);

const fetchAssociatedOrganizations = ({ id }, cookies) =>
  getList(ENDPOINTS.DYNAMIC.ORGANIZATION_RELATIONS(id), {}, cookies).then(response => response?.data);

const fetchManagementAndStaff = ({ id, params = {}, cookies }) =>
  getList(ENDPOINTS.DYNAMIC.ORGANIZATION_EMPLOYEES(id), params, cookies).then(response => response?.data);

const fetchOrganizationTypes = (_props, cookies) =>
  getWholeList(ENDPOINTS.STATIC.ORGANIZATIONS_TYPES, {}, cookies).then(response => {
    const total = response?.data?.total || 0;
    const order = shuffle(Array.from({ length: total }, (_i, i) => i + 1));

    return {
      ...response?.data,
      data: response?.data?.data?.map((item, index) => ({ ...item, order: order[index] || 0 })) || [],
    };
  });

const fetchOrgAggregation = ({ aggregationType, filters = {}, limit = 300, page = 1 }, cookies) => {
  const params = {
    queryParams: {
      aggregation_type: aggregationType,
      sort: SORT_OPTION_TYPES.NAME_ASC.value,
      ...omit(filters, [aggregationType]),
    },
    pagination: {
      limit,
      page,
    },
  };

  const endpoint = `${ENDPOINTS.STATIC.ORGANIZATIONS}/aggregations`;

  return getList(endpoint, params, cookies).then(response => response?.data);
};

const fetchTrendingOrganizations = ({ limit = 10, page = 1, filters }, cookies) => {
  const params = {
    queryParams: filters,
    pagination: {
      limit,
      page,
    },
  };

  return getList(`${ENDPOINTS.STATIC.ORGANIZATIONS}/trending`, params, cookies).then(response => response?.data);
};

const fetchSuggestedOrganizations = ({ limit = 10, page = 1, filters }, cookies) => {
  const params = {
    queryParams: filters,
    pagination: {
      limit,
      page,
    },
  };

  return getList(`${ENDPOINTS.STATIC.ORGANIZATIONS}/suggestions`, params, cookies).then(response => response?.data);
};

const fetchOrgDesignations = async ({ limit = 50, page = 1, filters }, cookies) => {
  const params = {
    queryParams: filters,
    pagination: {
      limit,
      page,
    },
  };

  return getList(ENDPOINTS.STATIC.ORGANIZATIONS_DESIGNATIONS, params, cookies).then(response => response?.data);
};

const queries = {
  getOrganizationAggregations: getQuery('GET_ORGANIZATION_AGGREGATIONS', fetchOrgAggregation),
  getAggregatedOrgTypes: getQuery(
    'GET_AGGREGATED_ORG_TYPES',
    transformQueryFn(fetchOrgAggregation, transformGetRandomisedOrderedList),
    props => ({
      ...props,
      aggregationType: AGGREGATION_TYPES.ORGANIZATION_TYPES,
    }),
  ),
  getOrganizationTypes: getQuery('GET_ORGANIZATION_TYPES', fetchOrganizationTypes),
  getOrganizations: getQuery('GET_ORGANIZATIONS', fetchOrganizations),
  getTrendingOrganizations: getQuery('GET_TRENDING_ORGANIZATIONS', fetchTrendingOrganizations),
  getCustomerLogos: getQuery(
    'GET_CUSTOMER_LOGOS',
    transformQueryFn(fetchSuggestedOrganizations, transformGetCustomerLogos),
  ),
  getOrganizationDetails: getQuery('GET_ORGANIZATION_DETAILS', fetchOrganization),
  getTranslations: getQuery('GET_ABOUT_ORGANIZATION', fetchTranslations),
  getOrgList: ({ queryParams, queryConfig = {} }, cookies, language) => {
    const pagination = { limit: 20 };
    const query = {
      queryParams,
    };
    return {
      queryKey: [getLanguage(language), 'GET_ORG_LIST', queryParams],
      queryFn: async () => getList(ENDPOINTS.STATIC.ORGANIZATIONS, query, pagination, cookies),
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getProductionRentalContacts: getQuery('GET_RENTAL_CONTACTS', fetchProductionRentalContacts),
  getAssociatedOrganizations: getQuery('GET_ASSOCIATED_ORGANIZATION', fetchAssociatedOrganizations),
  getManagementAndStaff: getQuery('GET_MANAGEMENT_STAFF', fetchManagementAndStaff),
  createOrganizationProfile: postQuery(ENDPOINTS.STATIC.ORGANIZATIONS),
  createOrganizationEmployee: postQuery(ENDPOINTS.DYNAMIC.ORGANIZATION_CERTAIN_EMPLOYEE),
  updateOrganizationEmployee: updateQuery(ENDPOINTS.DYNAMIC.ORGANIZATION_CERTAIN_EMPLOYEE),
  deleteOrganizationEmployee: deleteQuery(ENDPOINTS.DYNAMIC.ORGANIZATION_CERTAIN_EMPLOYEE),
  createOrganizationType: postQuery(ENDPOINTS.STATIC.ORGANIZATIONS_TYPES),
  createOrganizationContacts: postQuery(ENDPOINTS.DYNAMIC.ORGANIZATION_CONTACTS),
  createOrganizationAddress: postQuery(ENDPOINTS.DYNAMIC.ORGANIZATION_ADDRESSES),
  updateOrganizationWorkTypes: postQuery(ENDPOINTS.DYNAMIC.ORGANIZATION_WORK_TYPES),
  deleteOrganizationWorkTypes: deleteQuery(ENDPOINTS.DYNAMIC.ORGANIZATION_WORK_TYPES_DELETE),
  updateOrganizationStages: postQuery(ENDPOINTS.DYNAMIC.ORGANIZATION_STAGES),
  deleteOrganizationStage: deleteQuery(ENDPOINTS.DYNAMIC.ORGANIZATION_STAGE_DELETE),
  getOrgDesignations: getQuery('GET_ORG_DESIGNATIONS', fetchOrgDesignations),
};

export default queries;
