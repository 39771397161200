import { useMemo } from 'react';
import usePageContext from 'utils/hooks/usePageContext';
import { useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';
import { ENTITY_TYPE } from 'constants/index';

const createResponse = (entityType = null, profileData = null, targetEntity) => ({
  hasOrganizationAccess: entityType === ENTITY_TYPE.ORGANIZATION,
  hasAgencyAccess: entityType === ENTITY_TYPE.AGENCY,
  hasProfileAccess: entityType === ENTITY_TYPE.PROFILE,
  entityType,
  profileData,
  profile: targetEntity,
});

const useGetTargetProfile = profileId => {
  const { entity } = usePageContext();
  const { data: profileAccesses, ownerProfile } = useProfileAccesses();
  const resolvedProfileId = entity?.id || profileId || ownerProfile?.profile?.id;
  const hasContextProfile = entity?.id === profileId;

  const targetProfile = useMemo(() => {
    if (!profileAccesses?.data?.length) {
      return createResponse();
    }

    const targetEntity =
      profileAccesses.data.find(profile =>
        [profile?.organization?.id, profile?.agency?.id, profile?.profile?.id].includes(resolvedProfileId),
      ) || ownerProfile;

    if (!targetEntity) {
      return createResponse();
    }

    const { organization = null, agency = null, profile = null } = targetEntity;

    if (organization?.id && organization?.id === resolvedProfileId) {
      return createResponse(ENTITY_TYPE.ORGANIZATION, organization, targetEntity);
    }

    if (agency?.id && agency?.id === resolvedProfileId) {
      return createResponse(ENTITY_TYPE.AGENCY, agency, targetEntity);
    }

    if ((profile?.id && profile?.id === resolvedProfileId) || profile?.id) {
      return createResponse(ENTITY_TYPE.PROFILE, hasContextProfile ? entity : profile, targetEntity);
    }

    return createResponse();
  }, [profileAccesses.data, ownerProfile, resolvedProfileId, hasContextProfile, entity]);

  return targetProfile;
};

export default useGetTargetProfile;
