import React from 'react';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from './LinkButton';

import legacy_classes from './LinkButtonOld.module.scss';
import classes from './LinkButton.module.scss';

const MEDIA_ICON_SIZE_MAP = {
  large: 36,
  medium: 28,
  small: 18,
};

export const PrimaryButton = props => <LinkButton {...props} variant="primary" classes={classes} />;
export const SecondaryButton = props => <LinkButton {...props} variant="secondary" classes={classes} />;
export const TertiaryButton = props => <LinkButton {...props} variant="tertiary" classes={classes} />;
export const QuaternaryButton = props => <LinkButton {...props} variant="quaternary" classes={classes} />;
export const DarkButton = props => <LinkButton {...props} variant="dark" classes={classes} />;
export const DarkGoldButton = props => <LinkButton {...props} variant="dark-gold" classes={classes} />;
export const TextButton = props => <LinkButton {...props} variant="text" classes={classes} />;
export const MediaButton = props => (
  <DarkGoldButton
    {...props}
    leftIcon={<SpriteIcon icon="play_icon" size={MEDIA_ICON_SIZE_MAP[props?.size || 'large']} />}
    styles={{ root: classes.mediaBtnPadding }}
  />
);

export default props => <LinkButton classes={legacy_classes} {...props} />;
