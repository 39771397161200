const axios = require('axios');
const HttpAgent = require('agentkeepalive');
const { HttpsAgent } = require('agentkeepalive');

const axiosInstances = {};
const options = {
  keepAlive: true,
  maxSockets: 32,
  timeout: 20 * 1000,
};

const getHttpInstance = host => {
  if (!host) {
    throw new Error('Missing host for http instance');
  }

  if (!axiosInstances[host]) {
    if (typeof window !== 'undefined') {
      axiosInstances[host] = axios.create();
    } else {
      const httpAgent = new HttpAgent(options);
      const httpsAgent = new HttpsAgent(options);

      axiosInstances[host] = axios.create({
        httpAgent,
        httpsAgent,
      });
    }
  }

  return axiosInstances[host];
};

module.exports = {
  getHttpInstance,
};
