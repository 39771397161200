import { useEffect } from 'react';

import { GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';

import { getCookie, removeCookie } from 'utils/cookie';
import { pingSegment, TRACK_EVENTS } from 'utils/tracking';
import useInterval from 'utils/hooks/useInterval';

import { SOCIAL_LOGIN } from 'constants/cookieConstants';

const PING_SEGMENT_INTERVAL = 300000;

const useLegacyEvents = track => {

  const onCopy = e => {
    track.click(
      {
        name: 'copy',
        content: e?.target?.textContent,
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  };

  useEffect(() => {
    const isSocialLogin = getCookie(SOCIAL_LOGIN);

    if (isSocialLogin) {
      track.click(
        {
          name: TRACK_EVENTS.LOGGED_IN,
          login_type: 'social_login',
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
      removeCookie(SOCIAL_LOGIN, {
        domain: process.env.COOKIE_DOMAIN,
      });
    }

    window.addEventListener('copy', onCopy); // move this to analytics

    return () => {
      window.removeEventListener('copy', onCopy);
    };

    // NOTE: To be executed once per load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackSegment = () => {
    track.click(pingSegment(), GOOGLE_OLD_TRACKING_SERVICES);
  };
  useInterval(trackSegment, PING_SEGMENT_INTERVAL);
};

export default useLegacyEvents;
