import { createDate } from '../utils/date';

export const FACEBOOK = 'https://www.facebook.com/operabase/';
export const LINKEDIN = 'https://www.linkedin.com/company/operabase-global/about/';
export const TWITTER = 'https://twitter.com/Operabase_';
export const INSTAGRAM = 'https://www.instagram.com/operabaseglobal/';
export const CONTACT_EMAIL = 'contact@operabase.info';
export const MALTE_CONTACT_EMAIL = 'malte@operabase.com';
export const ARTIST_EDIT_HELP_VIDEO = 'https://vimeo.com/697472148';
export const ARTIST_EDIT_KNOWLEDGEBASE_LINK = 'https://help.operabase.com/knowledge/en/operabase-for-artists';

// Translations prefix
export const TP = 'operabase.general';

export const SNS_CONTACTS_NAMES = {
  CUETV: 'cuetv',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  YOUTUBE: 'youtube',
  VK: 'vk',
  VIMEO: 'vimeo',
  WIKIPEDIA: 'wikipedia',
};

export const CONTACTS_NAMES = {
  ADDRESS: 'address',
  EMAIL: 'email',
  PHONE: 'phone',
  POC_EMAIL: 'point_of_contact_email',
  POC_PHONE: 'point_of_contact_phone',
  BOX_OFFICE: 'box_office',
  WORK_EMAIL: 'work_email',
  WORK_PHONE: 'work_phone',
  WEBSITE: 'website',
  OTHER: 'other',
  SNS: 'sns',
  BOX_OFFICE_EMAIL: 'box_office_email',
  BOX_OFFICE_PHONE: 'box_office_phone',
  BOX_OFFICE_WEBSITE: 'box_office_website',
};

export const SOCIAL_LINKS = [
  { link: FACEBOOK, type: SNS_CONTACTS_NAMES.FACEBOOK },
  { link: TWITTER, type: SNS_CONTACTS_NAMES.TWITTER },
  { link: INSTAGRAM, type: SNS_CONTACTS_NAMES.INSTAGRAM },
  { link: LINKEDIN, type: SNS_CONTACTS_NAMES.LINKEDIN },
];

export const ARTIST_TYPE_ID = 1;
export const AGENT_TYPE_ID = 2;
export const AOS_TYPE_ID = 3;
export const AUDIENCE_TYPE_ID = 4;
export const CASTING_TYPE_ID = 6;
export const ACADEMICS_TYPE_ID = 7;
export const JOURNALIST_TYPE_ID = 8;

export const SINGER_PROFESSION_TYPE_ID = 1;
export const INSTRUMENTALIST_PROFESSION_TYPE_ID = 3;
export const ORCHESTRA_PROFESSION_TYPE_ID = 23;
export const CHOIR_PROFESSION_TYPE_ID = 24;

export const CAST_TYPE = {
  INSTRUMENTAL: 'instrumental',
  CHARACTER: 'character',
};

export const LISTING_PAGE = 'listingPage';

export const PRODUCTION_MODE = process.env.NODE_ENV === 'production';

export const contactsIdentifiers = {
  phone: 0,
  email: 1,
  sns: 2,
  website: 3,
  address: 4,
  other: 5,
};

export const DIGITAL_CONTACTS = [contactsIdentifiers.sns, contactsIdentifiers.website, contactsIdentifiers.other];

export const repertoireStatuses = {
  RETIRED: 'retire',
  WISHLIST: 'future',
  PAST: 'past', // active records. It is performances status actually
  PREPARED: 'prepared',
  UNPREPARED: 'unprepared',
};

export const futureRepertoireStatuses = [
  repertoireStatuses.WISHLIST,
  repertoireStatuses.PREPARED,
  repertoireStatuses.UNPREPARED,
];

export const PRODUCTION_ADDED_IDENTIFIERS_IDS = {
  UNKNOWN: 1,
  ADMIN: 2,
  COMPANY: 3,
  ARTIST: 4,
  AGENCY: 5,
  FESTIVAL: 6,
  ORGANIZATION: 7,
};

export const PRODUCTION_PARTNER_TYPE = {
  PRODUCER: 'producer',
  CO_PRODUCER: 'co-producer',
  HOST: 'host',
  OTHER: 'Other',
};

export const langs = {
  EN: 'en',
  BG: 'bg',
  DA: 'da',
  ES: 'es',
  FI: 'fi',
  HU: 'hu',
  LT: 'lt',
  NL: 'nl',
  PT: 'pt',
  SK: 'sk',
  CA: 'ca',
  DE: 'de',
  ET: 'et',
  FR: 'fr',
  IS: 'is',
  LV: 'lv',
  NO: 'no',
  RO: 'ro',
  SL: 'sl',
  CS: 'cs',
  EL: 'el',
  EU: 'eu',
  GA: 'ga',
  IT: 'it',
  MT: 'mt',
  PL: 'pl',
  RU: 'ru',
  SV: 'sv',
};

export const performancesAggregationTypes = {
  agencyAgg: 'agency_id',
  producerAgg: 'producer_id',
  companyAgg: 'company_id',
  orgAgg: 'organization_id',
  composerAgg: 'work_creator_id',
  countryAgg: 'country_id',
  cityAgg: 'city_id',
  crewProfileRefAgg: 'crew_profile_reference',
  festivalAgg: 'festival_id',
  labelAgg: 'label_id',
  productionAgg: 'production_id',
  professionAgg: 'profession_id',
  professionQualificationAgg: 'profession_qualification_id',
  profileAgg: 'profile_id',
  profileTypeAgg: 'profile_type_id',
  queryAgg: 'aggregation_query',
  regionAgg: 'region_id',
  seasonAgg: 'season_id',
  typeAgg: 'aggregation_type',
  venueAgg: 'venue_id',
  workAgg: 'work_id',
  workRoleAgg: 'work_role_id',
  workTypeAgg: 'work_type_id',
  yearAgg: 'year_id',
  individualId: 'individual_id',
  workRoleReferenceAgg: 'work_role_reference',
};

export const ARTICLE_AGGREGATION_TYPES = {
  YEAR: 'year',
  TITLE: 'work_id',
  COMPANY: 'company',
};

export const RENTALS_AGGREGATION_TYPES = {
  WORK_NAME: 'work_name',
  PRODUCTION_TEAM: 'production_team',
  COMPANY: 'company',
  SORT: 'sort',
};

export const PRODUCTION_RENTALS_AGGREGATION_TYPES = {
  WORK_ID: 'work_id',
  PRODUCTION_TEAM: 'contributor_id',
  COMPANY: 'organization_id',
  SORT: 'sort',
};

export const RENTALS_SORT_TYPES = {
  WORK_NAME: 'work_name',
  COMPOSER_NAME: 'composer_name',
  COMPANY_NAME: 'company_name',
  DIRECTOR_NAME: 'director_name',
  CONDUCTOR_NAME: 'conductor_name',
  DATE_FROM: 'date_from',
  DATE_TO: 'date_to',
  REFRESH_DATE: 'refresh_date',
};

export const STATISTICS_AGGREGATION_TYPES = {
  YEAR: 'year',
  COUNTRY: 'country_id',
  TYPE: 'type_id',
  SEASON: 'season_id',
};

export const COMPETITIONS_AGGREGATION_TYPES = {
  COMPETITION: 'competition_id',
  COUNTRY: 'country_id',
  CITY: 'city_id',
  STATUS: 'status',
  AGE_LIMIT_MEN: 'age_limit_men',
  AGE_LIMIT_WOMEN: 'age_limit_women',
};

export const dateFormats = {
  basic: 'YYYY-MM-DD',
  basicReversed: 'DD-MM-YYYY',
  slash: 'DD/MM/YYYY',
  productionMonth: "MMM'YY",
  shortTextMonthAndFullYear: 'MMM YYYY',
  year: 'YYYY',
  yearShort: 'YY',
  date: 'DD',
  dateAndMonth: 'DD MMMM',
  dayOfWeekShort: 'ddd',
  dayOfWeekName: 'dddd',
  event: 'DD MMMM YYYY',
  eventMonth: `MMMM YYYY`,
  shortMonthEvent: 'DD MMM YYYY',
  shortTextMonthAndYear: 'MMM YY',
  fullTextDayMonthYear: 'dddd, MMMM D, YYYY',
  dateTimeBasic: 'YYYY-MM-DD HH:mm',
  performanceDate: 'ddd, MMM DD',
  performanceDateAndYear: "ddd, DD MMM' YYYY",
  vodDate: "DD MMM' YYYY",
  monthYear: `MMM' YYYY`,
  slashPlaceholder: `${TP}.FN_DATE_FORMAT_SLASH`,
  abbrevatedTimezone: 'z',
  fullDateTimeEvent: 'DD MMM, YYYY | HH:mm',
  fullDate: 'DD MMM, YYYY',
};

export const PERFORMANCE_TYPES_ID = {
  WORLD_PREMIERE: 1,
  NATIONAL_PREMIERE: 2,
  CONCERT: 3,
  NEW_PRODUCTION: 4,
  CANCELLED: 5,
  TOUR: 6,
  FESTIVAL: 7,
  MATINEE: 8,
  DOUBLE_BILL: 9,
  RECITAL: 10,
  LIVESTREAM: 11,
  VIDEO_ON_DEMAND: 12,
};

export const PRODUCTION_TYPES_ID = {
  OTHERS: 5,
  CONCERT: 6,
  BALLET: 7,
  OPERA: 8,
  MUSICAL: 9,
};

export const CALENDAR_VIEW = 'calendar';
export const LIST_VIEW = 'list';

export const workTypes = [
  {
    id: 1,
    slug: 'O',
    name: 'Oratorio/ Orchestral',
  },
  {
    id: 2,
    slug: 'W',
    name: 'Opera',
  },
  {
    id: 3,
    slug: 'L',
    name: 'Operetta',
  },
  {
    id: 4,
    slug: 'Z',
    name: 'Zarzuella',
  },
  {
    id: 5,
    slug: 'T',
    name: 'Music-theatre',
  },
  {
    id: 6,
    slug: 'P',
    name: 'Derived',
  },
  {
    id: 7,
    slug: 'V',
    name: 'Vocal/ Song cycle',
  },
  {
    id: 8,
    slug: 'M',
    name: 'Musical',
  },
];

export const EMPTY_INFO_MARK = '--';

export const MISSING_PRODUCTION_ITEMS_IDS = {
  WORK_NAME: 1,
  COMPANY_NAME: 2,
  CASTS: 3,
  DATES: 4,
  PRODUCER: 5,
  FESTIVAL: 6,
};

export const MISSING_PRODUCTION_REQUIRED_ITEMS_IDS = {
  WORK_NAME: MISSING_PRODUCTION_ITEMS_IDS.WORK_NAME,
};

export const PRIVACY_ATTRIBUTE_IDS = {
  PRIVATE: 1,
  PUBLIC: 2,
  PRO: 3,
  FREE_AND_PRO: 4,
  CASTING: 5,
  ARTISTS: 6,
  AGENCY: 7,
  AOS: 8,
};

export const allPrivacyAttributesString = Object.values(PRIVACY_ATTRIBUTE_IDS).join(',');

export const PRIVACY_DEFAULT_ID = PRIVACY_ATTRIBUTE_IDS.PUBLIC;

export const CHURNED_ARTIST_DEFAULT_PRIVACY_STATE = [{ id: PRIVACY_ATTRIBUTE_IDS.CASTING }];

export const missingItemsLabels = {
  [MISSING_PRODUCTION_ITEMS_IDS.COMPANY_NAME]: 'SEARCH_COMPANY',
  [MISSING_PRODUCTION_ITEMS_IDS.CASTS]: 'm_CAST',
  [MISSING_PRODUCTION_ITEMS_IDS.WORK_NAME]: 'm_MUSICALWORK',
  [MISSING_PRODUCTION_ITEMS_IDS.DATES]: 'm_DATES',
  [MISSING_PRODUCTION_ITEMS_IDS.PRODUCER]: 'FN_PRODUCER',
  [MISSING_PRODUCTION_ITEMS_IDS.FESTIVAL]: 'FN_FESTIVAL',
};

export const PROFESSIONS_AVAILABILITY = {
  CREATE_PROFILE: 'create_profile',
  UPDATE_PROFILE: 'update_profile',
  GET_PROFILE: 'get_profile',
  CREATE_PRODUCTION: 'create_production',
  UPDATE_PRODUCTION: 'update_production',
  GET_PRODUCTION: 'get_production',
  CASTING_TOOL: 'casting_tool',
  CREATE_CREW_PROFILE: 'create_crew_profile',
};
export const TAG_TYPE = {
  AVAILABILITY: 'availability',
};

export const PARENT_PROFESSION_SLUG = {
  SINGER: 'singer',
  INSTRUMENTALIST: 'instrumentalist',
};

export const PAGINATION_DEFAULT_LIMIT = 10;

const baseUrl = extensions =>
  new RegExp(`^(http://www.|https://www.|http://|https://)?.+\\.(?:${extensions})(\\?)?(.*)?$`);

// eslint-disable-next-line no-useless-escape
export const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9\-]+([-\\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
export const noUrlRegex = /^((?![a-zA-Z0-9@:%_\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)).)*$/s;
export const imageUrlRegex = baseUrl('png|jpg|jpeg|gif|png');
export const yearRegex = /(\d{4}|\d{4}-\d{4})$/g;
export const cueTvMediaUrlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/).*cuetv\.online.*/g;
export const facebookMediaUrlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/).*facebook\.com.*/g;
export const vimeoMediaUrlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/).*vimeo\.com.*/g;
export const stagePlusMediaUrlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/).*stage-plus\.com.*/g;

export const MEDIA_TYPES_IDS = {
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  LS: 4,
  VOD: 5,
};

export const MEDIA_TAGS_TYPES_IDS = {
  PROFILE: 1,
  VENUE: 3,
  PRODUCTION: 4,
  COMPOSER: 5,
  DATE: 6,
  WORK: 8,
  CAST: 9,
  PRODUCTION_COMPANY: 10,
  PRODUCTION_FESTIVAL: 11,
  ORGANIZATION: 12,
  PRODUCTION_CONTRIBUTION: 13,
};

export const MEDIA_TAG_RELATIONS = {
  VENUE: 'venue',
  COMPOSER: 'composer',
  WORK: 'work',
  CAST: 'cast',
  ORGANIZATION: 'organization',
};

export const MEDIA_ATTRIBUTES_TYPES_IDS = {
  IMAGE_FORMAT: 1,
  WIDTH: 2,
  HEIGHT: 3,
  POSITION: 4,
  VIDEO_FORMAT: 5,
  DURATION: 6,
  GENRE: 7,
  TAGS: 8,
  AUDIO_FORMAT: 9,
  VIDEO: 11,
  IMAGE: 12,
  BANNER: 19,
  RECORDING_DATE: 60,
  ENTITY_ABOUT_VIDEO: 26,
};

export const MEDIA_STATUS = {
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  ERROR: 'error',
  NOT_AVAILABLE: 'not-available',
};

export const ASSOCIATED_STATUS = {
  NON_OPERATIONAL: `${TP}.FN_NON_OPERATIONAL`,
  OPERATIONAL: `${TP}.FN_OPERATIONAL`,
};

export const VIDEO_ATTRIBUTE_SLUGS = {
  LIVESTREAM: 'live-stream',
  VIDEO_ON_DEMAND: 'on-demand',
  PROFILE_BANNER_IMAGE: 'profile-banner-image',
  ENTITY_ALL_IMAGE: 'entity-all-images',
  ENTITY_ALL_PRODUCTION_IMAGE: 'entity-production-images',
  TRAILER: 'trailer',
  VIDEO_TYPE: 'video-type',
  ABOUT_ORGANIZATION: 'about-organization',

  // TODO: Org clean up
  ABOUT_COMPANY: 'about-company',
  ABOUT_FESTIVAL: 'about-festival',
};

export const IMAGE_ATTRIBUTE_SLUGS = {
  POSTER: 'poster',
  PART_OF_PRODUCTION: 'Part of Production',
  IMAGE_TYPE: 'image-type',
  HEIGHT: 'height',
  WIDTH: 'width',
  BANNER: 'banner',
};

export const VIDEO_ATTRIBUTE_TYPES_IDS = {
  START_DATE: 33,
  START_TIME: 34,
  END_DATE: 35,
  END_TIME: 36,
  TIMEZONE: 37,
  COST_TYPE: 41,
};

export const TAGS_AVAILABILITY_TYPES = {
  GALLERY: 'gallery',
  LABEL: 'label',
  POSTER: 'poster',
};

export const ARTIST_TAB_VALUES = {
  HOME: 'home',
  PERFORMANCES: 'performances',
  WORKS: 'works',
  REPERTORIE: 'repertoire',
  BIO: 'bio',
  VIDEOS: 'videos',
  IMAGES: 'images',
  REVIEWS: 'reviews',
  CONTACT: 'contact',
  PHOTOS: 'photos',
  AUDITION_SHOWREEL: 'audition-showreel',
  CAREER_GRAPH: 'career-graph',
  INSIGHTS: 'insights',
};

export const PRO_TAB_VALUES = {
  COMPANIES: 'companies',
  AGENCIES: 'agencies',
  ARTISTS: 'artists',
  ACADEMIA: 'academia',
  JOURNALIST: 'journalist',
};

export const CONTACT_TYPES = {
  BOX_OFFICE: 'box_office',
  COMPANY: 'company',
  FESTIVAL: 'festival',
  ARTIST: 'artist',
};

export const CONTACT_BLOCKS = {
  QUERIES: 'queries',
  FOR_AOS: 'forAOS',
  FOR_ARTIST: 'forArtist',
  FOR_AGENTS: 'forAgents',
  FOR_AUDIENCE: 'forAudience',
};

export const CONTACT_BLOCKS_LINKS = {
  [CONTACT_BLOCKS.QUERIES]: {
    seeAll: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/general-queries',
      [langs.FR]: 'https://help.operabase.com/fr/knowledge/en/questions-g%C3%A9n%C3%A9rales',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]: 'https://help.operabase.com/es/knowledge/en/consultas-generales',
      [langs.IT]: 'https://help.operabase.com/it/knowledge/en/domande-generali',
      [langs.GA]: 'https://help.operabase.com/de/knowledge/en/allgemeine-fragen',
    },
    renew: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/subscription-faqs ',
      [langs.FR]: 'https://help.operabase.com/fr/knowledge/en/gestion-de-vos-donn%C3%A9es-de-paiement-et-de-carte',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]: 'https://help.operabase.com/es/knowledge/en/administrar-tus-pagos-y-los-detalles-de-tu-tarjeta',
      [langs.IT]:
        'https://help.operabase.com/it/knowledge/en/gestisci-il-pagamento-e-i-dettagli-della-tua-carta-di-credito',
      [langs.GA]: 'https://help.operabase.com/de/knowledge/en/verwaltung-ihrer-zahlungs-und-kartendaten',
    },
    update: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/subscription-faqs',
      [langs.FR]: 'https://help.operabase.com/fr/knowledge/en/gestion-de-vos-données-de-paiement-et-de-carte',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]: 'https://help.operabase.com/es/knowledge/en/administrar-tus-pagos-y-los-detalles-de-tu-tarjeta',
      [langs.IT]:
        'https://help.operabase.com/it/knowledge/en/gestisci-il-pagamento-e-i-dettagli-della-tua-carta-di-credito',
      [langs.GA]: 'https://help.operabase.com/de/knowledge/en/verwaltung-ihrer-zahlungs-und-kartendaten',
    },
    faq: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/general-faqs',
      [langs.FR]: 'https://help.operabase.com/fr/knowledge/en/questions-g%C3%A9n%C3%A9rales',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]: 'https://help.operabase.com/es/knowledge/en/consultas-generales',
      [langs.IT]: 'https://help.operabase.com/it/knowledge/en/domande-generali',
      [langs.GA]: 'https://help.operabase.com/de/knowledge/en/allgemeine-fragen',
    },
  },
  [CONTACT_BLOCKS.FOR_AOS]: {
    seeAll: {
      [langs.EN]:
        'https://help.operabase.com/knowledge/en/operabase-for-arts-organisations-companies-theatres-opera-houses-festivals-orchestras',
      [langs.FR]: 'https://help.operabase.com/fr/knowledge/en/operabase-pour-les-organisations-artistiques',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]: 'https://help.operabase.com/es/knowledge/en/operabase-para-organizaciones-art%C3%ADsticas',
      [langs.IT]: 'https://help.operabase.com/it/knowledge/en/operabase-per-organizzazioni-artistiche',
      [langs.GA]: 'https://help.operabase.com/de/knowledge/en/operabase-f%C3%BCr-kulturorganisationen',
    },
    manage: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/organisation-page',
      [langs.FR]: 'https://help.operabase.com/fr/knowledge/en/saisons-aper%C3%A7u',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]: 'https://help.operabase.com/es/knowledge/en/temporadas-vista-general',
      [langs.IT]: 'https://help.operabase.com/it/knowledge/en/stagioni-panoramica',
      [langs.GA]: 'https://help.operabase.com/de/knowledge/en/spielzeiten-%C3%BCbersicht',
    },
    useCastingTools: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/adding-a-production-1',
      [langs.FR]:
        'https://help.operabase.com/fr/knowledge/en/comment-ajouter-une-production-au-profil-de-mon-organisation-artistique',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]:
        'https://help.operabase.com/es/knowledge/en/c%C3%B3mo-a%C3%B1adir-una-producci%C3%B3n-al-perfil-de-mi-compa%C3%B1%C3%ADa#',
      [langs.IT]:
        'https://help.operabase.com/it/knowledge/en/come-aggiungo-una-produzione-al-mio-profilo-organizzazione-artistica',
      [langs.GA]:
        'https://help.operabase.com/de/knowledge/en/wie-f%C3%BCge-ich-eine-produktion-zu-meinem-firmenprofil-hinzu',
    },
  },
  [CONTACT_BLOCKS.FOR_AGENTS]: {
    seeAll: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/operabase-for-agents-artist-managers',
      [langs.FR]: 'https://help.operabase.com/fr/knowledge/en/operabase-pour-les-agents',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]: 'https://help.operabase.com/es/knowledge/en/operabase-para-agentes',
      [langs.IT]: 'https://help.operabase.com/it/knowledge/en/operabase-per-agenti',
      [langs.GA]: 'https://help.operabase.com/de/knowledge/en/operabase-f%C3%BCr-agentinnen',
    },
    manageRoster: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/agency-roster-management-faqs',
      [langs.FR]:
        'https://help.operabase.com/fr/knowledge/en/vue-densemble-de-la-page-de-votre-agence-operabase-professional',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]:
        'https://help.operabase.com/es/knowledge/en/vista-general-de-su-p%C3%A1gina-operabase-professional-para-agencias',
      [langs.IT]:
        'https://help.operabase.com/it/knowledge/en/panoramica-della-pagina-operabase-professional-della-tua-agenzia',
      [langs.GA]: 'https://help.operabase.com/de/knowledge/en/operabase-f%C3%BCr-agentinnen',
    },
    manageContact: {
      [langs.EN]:
        'https://help.operabase.com/knowledge/en/getting-started-1?__hstc=240031068.f3c1e7b66733d9f1136ca88bdad85f19.1712140496489.1713320574757.1713324540797.46&__hssc=240031068.2.1713324540797&__hsfp=1513134924',
      [langs.FR]:
        'https://help.operabase.com/fr/knowledge/en/vue-densemble-de-la-page-de-votre-agence-operabase-professional',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]:
        'https://help.operabase.com/es/knowledge/en/vista-general-de-su-p%C3%A1gina-operabase-professional-para-agencias',
      [langs.IT]:
        'https://help.operabase.com/it/knowledge/en/panoramica-della-pagina-operabase-professional-della-tua-agenzia',
      [langs.GA]: 'https://help.operabase.com/de/knowledge/en/operabase-f%C3%BCr-agentinnen',
    },
  },
  [CONTACT_BLOCKS.FOR_ARTIST]: {
    seeAll: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/operabase-for-artists',
      [langs.FR]: 'https://help.operabase.com/fr/knowledge/en/operabase-pour-les-artistes',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]: 'https://help.operabase.com/es/knowledge/en/operabase-para-artistas',
      [langs.IT]: 'https://help.operabase.com/it/knowledge/en/operabase-per-artisti',
      [langs.GA]: 'https://help.operabase.com/de/knowledge/en/operabase-f%C3%BCr-k%C3%BCnstler',
    },
    benefits: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/subscription-faqs',
      [langs.FR]: 'https://help.operabase.com/fr/knowledge/en/operabase-pour-les-artistes',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]:
        'https://help.operabase.com/es/knowledge/en/administrar-caracter%C3%ADsticas-b%C3%A1sicas-en-su-perfil-de-artista-nombre-profesi%C3%B3n-presencia-digital-y-biograf%C3%ADa',
      [langs.IT]:
        'https://help.operabase.com/it/knowledge/en/gestisci-le-funzionalit%C3%A0-base-nel-tuo-profilo-artista-nome-professione-presenza-digitale-e-biografia',
      [langs.GA]:
        'https://help.operabase.com/de/knowledge/en/verwalten-grundlegender-funktionen-auf-ihrem-k%C3%BCnstlerprofil-name-beruf-digitale-pr%C3%A4senz-und-biografie',
    },
    manage: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/operabase-for-artists#manage-your-profile',
      [langs.FR]: 'https://help.operabase.com/fr/knowledge/en/operabase-pour-les-artistes',
      [langs.RU]: 'https://help.operabase.com/ru/knowledge/en',
      [langs.ES]:
        'https://help.operabase.com/es/knowledge/en/administrar-caracter%C3%ADsticas-b%C3%A1sicas-en-su-perfil-de-artista-nombre-profesi%C3%B3n-presencia-digital-y-biograf%C3%ADa',
      [langs.IT]:
        'https://help.operabase.com/it/knowledge/en/gestisci-le-funzionalit%C3%A0-base-nel-tuo-profilo-artista-nome-professione-presenza-digitale-e-biografia',
      [langs.GA]:
        'https://help.operabase.com/de/knowledge/en/verwalten-grundlegender-funktionen-auf-ihrem-k%C3%BCnstlerprofil-name-beruf-digitale-pr%C3%A4senz-und-biografie',
    },
  },
  [CONTACT_BLOCKS.FOR_AUDIENCE]: {
    seeAll: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/artists-search-discovery',
    },
    search: {
      [langs.EN]: 'https://help.operabase.com/knowledge/en/performances-tickets',
    },
    benefits: {
      [langs.EN]:
        'https://help.operabase.com/knowledge/en/managing-basic-features-on-your-artist-profile-name-profession-digital-presence-and-biography',
    },
    manage: {
      [langs.EN]:
        'https://help.operabase.com/knowledge/en/managing-basic-features-on-your-artist-profile-name-profession-digital-presence-and-biography',
    },
  },
};

export const PRODUCTION_TAB_VALUES = {
  OVERVIEW: 'overview',
  PERFORMANCES: 'performances',
  CAST_AND_CREW: 'cast-and-crew',
  VIDEOS: 'videos',
  IMAGES: 'images',
  VENUES: 'venue',
  ABOUT: 'about',
  REVIEWS: 'reviews',
};

export const COMPOSERS_TAB_VALUES = {
  HOME: 'home',
  WORKS: 'works',
  PERFORMANCES: 'performances',
  VIDEOS: 'videos',
  IMAGES: 'images',
  REVIEWS: 'reviews',
};

export const ROLE = 'role';
export const PROFESSION = 'profession';

export const emptySelectValue = { value: '', label: '' };

export const COMPANY_TAB_VALUES = {
  HOME: 'home',
  SEASONS: 'seasons',
  VIDEOS: 'videos',
  IMAGES: 'images',
  REVIEWS: 'reviews',
  CONTACT: 'contact',
  VENUES: 'venues',
  ABOUT: 'about',
  RENTALS: 'rentals',
  STAFF: 'management-staff',
};

export const PRODUCTION_DOCUMENT_TYPES = {
  SYNOPSIS: 'synopsis',
  BOOKLET: 'booklet',
  ACT: 'act',
  DESCRIPTION: 'description',
  SYNOPSIS_ACT: 'synopsisAct',
  BOOKLET_ACT: 'bookletAct',
  SUPPORT_PRODUCTION_PAGE: 'supportProductionPage',
  SIGNED_CONTRACT: 'supportSignedContract',
  PRODUCTION_REVIEW: 'supportProductionReview',
  PRODUCTION_PROGRAMME: 'supportProductionProgramme',
  PRODUCTION_CHANGE_REQUEST_PROOF: 'supportChangeRequestProof',
};

export const MS_TO_DAY_DIVIDER = 86400000;

export const MONTHS = [
  `${TP}.m_JANUARY`,
  `${TP}.m_FEBRUARY`,
  `${TP}.m_MARCH`,
  `${TP}.m_APRIL`,
  `${TP}.m_MAY`,
  `${TP}.m_JUNE`,
  `${TP}.m_JULY`,
  `${TP}.m_AUGUST`,
  `${TP}.m_SEPTEMBER`,
  `${TP}.m_OCTOBER`,
  `${TP}.m_NOVEMBER`,
  `${TP}.m_DECEMBER`,
];

export const FESTIVAL_TAB_VALUES = {
  HOME: 'home',
  PERFORMANCES: 'performances',
  VIDEOS: 'videos',
  IMAGES: 'images',
  REVIEWS: 'reviews',
  CONTACT: 'contact',
  VENUES: 'venues',
  ABOUT: 'about',
  RENTALS: 'rentals',
};

export const LOGO_TYPE = {
  CIRCLE: 1,
  SQUARE: 2,
  RECTANGLE: 3,
};

export const VENUE_TAB_VALUES = {
  HOME: 'home',
  PERFORMANCES: 'performances',
  VIDEOS: 'videos',
  IMAGES: 'images',
  REVIEWS: 'reviews',
  CONTACT: 'contact',
  ABOUT: 'about',
};

export const WORKS_TAB_VALUES = {
  HOME: 'home',
  PERFORMANCES: 'performances',
  VIDEOS: 'videos',
  IMAGES: 'images',
  REVIEWS: 'reviews',
  ABOUT: 'about',
};

export const PRODUCTION_VIEW_TYPES = {
  PERFORMANCE: 'performance',
  PRODUCTION: 'production',
};

export const FILE_TYPE = {
  PDF: 'application/pdf',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
};

export const COMPLETED_PROFILE_SECTIONS_NAMES = {
  BASIC_INFORMATION: 'Basic Information',
  CONTACTS: 'Contact Information',
  DIGITAL_PRESENCE: 'Digital Presence',
  MANAGEMENT_STAFF: 'Management & Staff',
  VENUES: 'Venues',
  NAME: 'Name',
  PROFESSION: 'Profession',
  BIOGRAPHY: 'Biography',
  VIDEO: 'Video',
  IMAGES: 'Images',
  PUBLICATIONS: 'Reviews & Publications',
};

export const MEDIA_TABS = {
  SYNOPSIS: 'synopsis',
  IMAGES: 'images',
  VIDEO: 'video',
  VALIDATION: 'validation',
};

export const VALIDATION_STATUSES = {
  PENDING: 1,
  PROCESSING: 2,
  APPROVED: 3,
  REJECTED: 4,
  APPROVED_HIDDEN: 5,
};

export const VALID_EDIT_DISPLAY_MEDIA_VALIDATION_STATUS = `${VALIDATION_STATUSES.PENDING},${VALIDATION_STATUSES.PROCESSING},${VALIDATION_STATUSES.APPROVED},${VALIDATION_STATUSES.APPROVED_HIDDEN}`;

export const defaultProductionPagination = { limit: 20, page: 1 };

export const PAGE_NAMES = {
  COMPANY: 'company',
  ARTIST: 'artist',
  FESTIVAL: 'festival',
  VENUES: 'venues',
  PRODUCTION: 'production',
  WORKS: 'works',
  ORGANIZATION: 'organization',
};

export const VISIBILITY = {
  ALL: 'all',
  VISIBLE: 'visible',
};

export const VALIDATION_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  NEW: 'new',
  APPROVED_HIDDEN: 'approved-hidden',
  REJECTED: 'rejected',
  PENDING_VALIDATION: 'pending_validation',
};

export const MIN_YEAR = 1945;
export const MAX_YEAR = new Date().getFullYear() + 5;

export const REPERTOIRE_SOURCE = {
  USER: 'user',
  PERFORMANCE: 'performance',
};

export const ARTIST_SORT_ORDER = 'last_name,first_name';
export const EVENT_SEO_STATUS_CANCELLED = 'https://schema.org/EventCancelled';
export const EVENT_SEO_STATUS_SCHEDULED = 'https://schema.org/EventScheduled';
export const PRODUCTION_TYPE_CANCELLED = 'cancelled';
export const EVENT_SEO_OFFERS_AVAILABILITY = 'https://schema.org/InStock';
export const EVENT_SEO_ATTENDANCE_MODE_OFFILNE = 'https://schema.org/OfflineEventAttendanceMode';
export const EVENT_SEO_ATTENDANCE_MODE_ONLINE = 'https://schema.org/OnlineEventAttendanceMode';

export const PAGERULE_ENTITY_TYPE = {
  ARTIST: 'profiles',
  AGENCY: 'agencies',
  AOS: 'companies',
  PRODUCTIONS: 'productions',
  COMPOSER: 'workComposers',
};

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'active',
  RENEWING: 'renewing',
  GRACE: 'grace',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
  DRAFT: 'draft',
  PENDINGENDING: 'pendingending',
  ENDED: 'ended',
  HOLD: 'hold',
  PROCESSING: 'processing',
};

export const INVOICE_STATUSES = {
  PAID: 'booked-paid',
  POSTED: 'processing-nonpaid',
  PAYMENT_DUED: 'booked-dunning',
  NON_PAID: 'booked-nonpaid',
  VOIDED: 'cancelled-nonpaid',
  PENDING: 'processing-pendingpayment',
};

export const COUPON_DISCOUNT_TYPES = {
  percentage: 'percentage',
  fixed: 'fixed_amount',
};

const TRANSFORMATION_BASE = {
  crop: 'thumb',
  quality: 100,
};

export const TRANSFORMATION_VENUE_IMAGE = {
  crop: 'thumb',
  quality: 100,
  height: 170,
  width: 270,
};

export const TRANSFORMATION_VENUE_IMAGE_THUMB = {
  crop: 'thumb',
  quality: 100,
  height: 85,
  width: 135,
};

const TRANSFORMATION_PROFILE_IMAGES = {
  gravity: 'face',
  ...TRANSFORMATION_BASE,
};

const TRANSFORMATION_PROFILE_BASE = {
  crop: 'fill',
  gravity: 'face',
  quality: 100,
};

export const TRANSFORMATION_ARTIST_PROFILE = {
  width: 171,
  height: 228,
  ...TRANSFORMATION_PROFILE_BASE,
};

export const TRANSFORMATION_ARTIST_BIO = {
  width: 307,
  height: 472,
  radius: 8,
  ...TRANSFORMATION_PROFILE_BASE,
};

export const TRANSFORMATION_ARTIST_PROFILE_BANNER = {
  width: 320,
  height: 420,
  ...TRANSFORMATION_PROFILE_BASE,
};
export const TRANSFORMATION_ARTIST_PROFILE_SMALL = {
  width: 99,
  height: 137,
  ...TRANSFORMATION_PROFILE_BASE,
};

export const TRANSFORMATION_ARTIST_PROFILE_SMALLEST = {
  width: 75,
  height: 100,
  zoom: 0.7,
  ...TRANSFORMATION_PROFILE_IMAGES,
};

export const TRANSFORMATION_ARTIST_PROFILE_LISTING = {
  width: 47,
  height: 47,
  crop: 'thumb',
  gravity: 'face',
  quality: 100,
  radius: 50,
};

export const TRANSFORMATION_EDIT_PAGE_LOGO = {
  width: 60,
  height: 60,
  radius: 8,
  quality: 100,
  crop: 'fit',
};

export const TRANSFORMATION_AVATAR_CIRCLE = {
  width: 100,
  height: 100,
  ...TRANSFORMATION_PROFILE_IMAGES,
  zoom: 0.7,
  radius: 50,
};

export const TRANSFORMATION_EDIT_PAGE_ARTIST_PROFILE = {
  ...TRANSFORMATION_AVATAR_CIRCLE,
  ...TRANSFORMATION_PROFILE_IMAGES,
  width: 60,
  height: 60,
  radius: 60,
  quality: 100,
};

export const TRANSFORMATION_LOGO_SQUARE = {
  crop: 'scale',
  quality: 100,
  radius: 8,
};

export const TRANSFORMATION_FESTIVAL_LOGO_CIRCLE = {
  width: 140,
  height: 140,
  crop: 'scale',
  quality: 100,
  radius: 100,
};

export const TRANSFORMATION_FESTIVAL_LOGO_SQUARE = {
  width: 154,
  height: 152,
  crop: 'scale',
  quality: 100,
  radius: 8,
};
export const TRANSFORMATION_LOGO_CIRCLE_SMALL = {
  width: 85,
  height: 85,
  ...TRANSFORMATION_BASE,
  radius: 100,
};

export const TRANSFORMATION_GALLERY_IMAGE_SMALL = {
  width: 52,
  height: 52,
  ...TRANSFORMATION_PROFILE_IMAGES,
  radius: 8,
};

export const TRANSFORMATION_ARTIST_SUGGESTIONS = {
  width: 48,
  height: 48,
  zoom: 0.7,
  ...TRANSFORMATION_PROFILE_IMAGES,
};

export const TRANSFORMATION_GALLERY_IMAGE = {
  width: 212,
  height: 212,
  ...TRANSFORMATION_PROFILE_IMAGES,
  crop: 'fill',
  radius: 8,
};
export const TRANSFORMATION_GALLERY_IMAGE_MEDIUM = {
  width: 188,
  height: 188,
  ...TRANSFORMATION_PROFILE_IMAGES,
  crop: 'fill',
  radius: 8,
};

export const TRANSFORMATION_GALLERY_IMAGE_SIDECARD = {
  height: 90,
  crop: 'fill',
  radius: 8,
};

export const TRANSFORMATION_PRODUCTION_POSTER = {
  width: 176,
  height: 220,
  ...TRANSFORMATION_PROFILE_IMAGES,
  radius: 4,
};

export const TRANSFORMATION_PRODUCTION_POSTER_NEW = {
  width: 186,
  height: 274,
  ...TRANSFORMATION_PROFILE_IMAGES,
};

export const TRANSFORMATION_PRODUCTION_IMAGES = {
  width: 216,
  height: 216,
  ...TRANSFORMATION_PROFILE_IMAGES,
  radius: 4,
};

export const TRANSFORMATION_ARTIST_IMAGES = {
  width: 216,
  height: 216,
  ...TRANSFORMATION_PROFILE_IMAGES,
  radius: 4,
};

export const TRANSFORMATION_ARTIST_VIDEOS = {
  width: 216,
  height: 130,
  ...TRANSFORMATION_PROFILE_IMAGES,
  radius: 4,
};

export const TRANSFORMATION_PROFILE_PHOTO = {
  width: 176,
  height: 220,
  ...TRANSFORMATION_PROFILE_IMAGES,
  radius: 4,
};

export const TRANSFORMATION_COVER_PHOTO = {
  width: 270,
  height: 170,
  ...TRANSFORMATION_PROFILE_IMAGES,
  radius: 4,
};

export const TRANSFORMATION_PRODUCTION_VIDEOS = {
  width: 168,
  height: 94,
  crop: 'fill',
};

export const TRANSFORMATION_VIDEO_CARD = {
  width: 212,
  height: 129,
  crop: 'fill',
};
export const TRANSFORMATION_VOD_CARD = {
  width: 176,
  height: 240,
  crop: 'fill',
  gravity: 'north',
};
export const TRANSFORMATION_BANNER = {
  width: 1024, // aspect ratio 4:1
  height: 256, // aspect ratio 4:1
  crop: 'fill',
  quality: 100,
  gravity: 'auto',
  aspectRatio: 0.25,
};

export const proRoutes = ['prosub', 'pro', 'proagent', 'proartist', 'casting', 'academia', 'journalist'];
export const audienceRoutes = ['contact', 'intro'];

export const STATIC_ASSET_URL = `${process.env.STATIC_ASSET_URL}/${process.env.BUILD_ID}`;

export const breakpoints = {
  mobile: 475,
  mobileLandScape: 600,
  tablet: 769,
  iPad: 1024,
};

export const showDateFormats = {
  getMonthDayAndYear: (year, month, day) => createDate(`${year}/${month}/${day}`).format('MMMM DD, YYYY'),
  getMonthAndYear: (year, month) => createDate(`${year}/${month}`).format('MMMM, YYYY'),
  getDayAndYear: (year, day) => createDate(`${year}/${day}`).format('DD, YYYY'),
  getMonthAndDay: (month, day) => createDate(`${month}/${day}`).format('MMMM DD'),
  getYear: year => createDate(`${year}`).format('YYYY'),
  getMonth: month => createDate(`${month}`).format('MMMM'),
  getDay: day => createDate(`${day}`).format('DD'),
};

export const profileType = {
  PRO: 'pro',
  FREE: 'free',
  CHURNED: 'churned',
  BASIC: 'basic',
};

export const PAYMENT_TYPE = {
  FREE: 'free',
  SUBSCRIPTION: 'subscription',
  PYA_PER_VIEW: 'pay-per-view',
};

export const STATS_TYPES = {
  PERFORMANCES: 'performances',
  COMPANIES: 'companies',
  FESTIVALS: 'festivals',
  ARTISTS: 'artists',
  MANAGERS: 'managers',
};

export const ARTIST_STUB_TYPES = {
  REPERTOIRE: 'Repertoire',
  PERFORMANCES: 'Performances',
  CONTACT: 'Contact',
};

export const AUDIENCE_STUB_TYPES = {
  RARE: 'rare',
  HIGHLIGHTS: 'Highlights',
  STATISTICS: 'Statistics',
};

export const CASTING_TOOL_STUB_TYPES = {
  ARTIST: 'artist',
  MUSICAL_WORK: 'musical work',
  CONTACT: 'Contact',
};

export const AUDIENCE_INFO = {
  ACTIVITY: 'activity',
  PERFORMANCES: 'performances',
  GLOBAL: 'global',
};

export const AUDIENCE_TABS = {
  ARTIST: 'artist',
  MUSICAL_WORK: 'musical work',
  COMPOSER: 'composer',
  CITY: 'city',
  COUNTRY: 'country',
  DATES: 'dates',
};

export const SUBSCRIPTION_DURATION = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  FREE: 'free',
};

export const PROFILE_IMAGE_PLACEHOLDER = '/images/Profile_Placeholder.png';
export const COMPANY_IMAGE_PLACEHOLDER = '/images/Company_Placeholder.png';

export const REVIEW_TYPES = {
  WORKS: 1,
  WORK_COMPOSER: 2,
  PRODUCTIONS: 3,
  PRODUCTION_DATES: 4,
  PRODUCTION_CASTS: 5,
  PROFILES: 6,
  WORK_ROLES: 7,
  VENUES: 8,
};
export const PRO_LOGO_TITLES = {
  ARTIST: 'Artist',
  AGENCY: 'Agency Casting Tool',
  AGENCY_FREE: 'Agency Roster',
  AOS: 'Arts Organisation',
  AOS_FREE: 'Public Relations',
  CASTING_TOOL: 'Casting Tool',
  ARCHIVE_ACCESS: 'Archive Access',
};

export const ENSEMBLES_TYPE_IDS = {
  CHOIR: 1,
  ORCHESTRA: 2,
  BALLET: 3,
  OTHERS: 4,
};

export const ENSEMBLES_TYPE_NAMES = {
  CHOIR: 'Choir',
  ORCHESTRA: 'Orchestra',
  BALLET: 'Ballet',
  OTHER: 'Other',
};

export const ENSEMBLES_TYPE_SLUGS = {
  CHOIR: 'choir',
  ORCHESTRA: 'orchestra',
  BALLET: 'ballet',
  OTHERS: 'others',
};

export const CONTRIBUTION_TYPES = {
  ENSEMBLE: 'ensemble',
  CAST: 'cast',
  CREW: 'crew',
  OTHER: 'other',
  PRODUCER: 'producer',
};

export const ENSEMBLES_TYPES = {
  CHIOR: {
    ID: 1,
    NAME: 'Choir',
    SLUG: 'choir',
  },
  ORCHESTRA: {
    ID: 2,
    NAME: 'Orchestra',
    SLUG: 'orchestra',
  },
  BALLET: {
    ID: 3,
    NAME: 'Ballet',
    SLUG: 'ballet',
  },
  OTHERS: {
    ID: 4,
    NAME: 'Others',
    SLUG: 'others',
  },
};

export const PRODUCTION_ACTION_TYPES = {
  CANCEL: 'cancel',
  UNCANCEL: 'uncancel',
  REQUEST_VALIDATION: 'request_validation',
};

export const modalState = {
  OPEN: true,
  CLOSE: false,
};
export const modalViewToRender = {
  REVIEWS: 'REVIEWS',
  SUMMARY: 'SUMMARY',
};
export const VENUE_DATE_TYPE = {
  PREF: 'pref',
  OTHER: 'other',
};

export const CAST_ACTION_IDENTIFIERS = {
  ADD: 'add',
  ADD_NEW: 'add_new',
  EDIT: 'edit',
};

export const CAST_ACTION_TYPE = {
  ADDED: 'added',
  DELETED: 'deleted',
};

export const CAST_ENTITY_TYPES = {
  ENTITY: 'entity',
  COVER_ENTITY: 'cover_entity',
};

export const PROD_TEAM_TYPES = {
  CAST: 'cast',
  CREW: 'crew',
  ENSEMBLE: 'ensemble',
};

export const MEDIA_UPLOAD_TYPES = {
  BLOB: 'blob',
  URL: 'url',
};

export const ENTITIES = {
  PROFILE_ENTITY: 1,
  PRODUCTION_ENTITY: 4,
  DATE_ENTITY: 6,
  WORK_ENTITY: 8,
  CAST_ENTITY: 9,
  ORGANIZATION: 12,
  PRODUCTION_CONTRIBUTION: 13,
};

export const ENTITY_RELATION = {
  CAST: 'cast',
  WORK: 'work',
  COMPOSER: 'composer',
  VENUE: 'venue',
  ORGANIZATION: 'organization',
};

export const FORM_TYPE = {
  CREATE: 'create',
  UPDATE: 'update',
};

export const REVIEW_ENTITIES = {
  WORKS: 1,
  COMPOSERS: 2,
  PRODUCTIONS: 3,
  PRODUCTION_DATES: 4,
  PRODUCTION_CASTS: 5,
  PROFILES: 6,
  WORK_ROLES: 7,
  VENUES: 8,
  ORGANIZATIONS: 13,
  PRODUCTION_CONTRIBUTION: 14,
  COMPANIES: 9,
  FESTIVALS: 10,
  PRODUCTION_COMPANY: 11,
  PRODUCTION_FESTIVAL: 12,
};

export const REVIEW_ENITITES_TYPES = {
  WORKS: { VALUE: 1, NAME: 'works' },
  COMPOSER: { VALUE: 2, NAME: 'workComposers' },
  PRODUCTIONS: { VALUE: 3, NAME: 'productions' },
  PRODUCTION_PERFORMANCE: { VALUE: 4, NAME: 'productionPerformances' },
  PRODUCTION_CAST: { VALUE: 5, NAME: 'productionCasts' },
  PROFILE: { VALUE: 6, NAME: 'profiles' },
  WORK_ROLE: { VALUE: 7, NAME: 'workRoles' },
  VENUE: { VALUE: 8, NAME: 'venues' },
  COMPANIES: { VALUE: 9, NAME: 'companies' },
  FESTIVALS: { VALUE: 10, NAME: 'festivals' },
  PRODUCTION_COMPANIES: { VALUE: 11, NAME: 'productionCompanies' },
  PRODUCTION_FESTIVALS: { VALUE: 12, NAME: 'productionFestivals' },
  ORGANIZATIONS: { VALUE: 13, NAME: 'organizations' },
  PRODUCTION_CONTRIBUTIONS: { VALUE: 14, NAME: 'productionContributions' },
};

export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
};

export const TEXT_TRANSFORM = {
  UPPER_CASE: 'uppercase',
  CAPITALISE: 'capitalize',
};

export const INDICATOR_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const AUTOCOMPLETE_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const LOADER_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const PRODUCTION_UPDATE_MODE = {
  VIEW: 'view',
  EDIT: 'edit',
  REVOKE_RED_MASK: 'revoke_redmask',
};

export const ENTITY_ACTION_TYPE = {
  NEW: 'new',
  EDIT: 'edit',
};

export const PRODUCTION_CHANGE_REQUEST_ACTION = {
  SUBMIT: 'submit',
};

export const DATE_TIME_SEPERATOR = ' ';
export const PERFOMANCE_DATE_MODE_TYPES = {
  STAGE: 'stage',
  LIVESTREAM: 'digital-ls',
  FULL_VIDEO: 'digital-vod',
};

export const FIELD_VARIANTS = {
  DEFAULT: '',
  FILLED: 'filled',
};

export const DROPZONE_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const source = {
  VIMEO: {
    id: 1,
    name: 'Vimeo',
    slug: 'vimeo',
    img: '/images/source-partner-icons/vimeo.svg',
  },
  YOUTUBE: {
    id: 2,
    name: 'Youtube',
    slug: 'youtube',
    img: '/images/source-partner-icons/youtube.svg',
  },
  FACEBOOK: {
    id: 3,
    name: 'Facebook',
    slug: 'facebook',
    img: '/images/source-partner-icons/facebook.svg',
  },
};

const PARTNERS = {
  CUETV: 'CueTv',
};

export const partnerType = {
  [PARTNERS.CUETV]: {
    id: 1,
    name: 'CueTV',
    slug: 'cuetv',
    img: '/images/source-partner-icons/cuetv.svg',
  },
};

export const GENDER_OPTIONS = {
  MALE: {
    label: `${TP}.FN_MALE_GENDER`,
    value: 'male',
  },
  FEMALE: {
    label: `${TP}.FN_FEMALE_GENDER`,
    value: 'female',
  },
  OTHER: {
    label: `${TP}.FN_OTHER_GENDER`,
    value: 'other',
  },
  PREFER_NOT_SAY: {
    label: `${TP}.FN_PREFER_NOT_SAY_GENDER`,
    value: 'prefer_not_to_say',
  },
};

export const PROFILE_ACTION_TYPES = {
  REVIEW_ORDERING: 'review-ordering',
  MEDIA_ORDERING: 'media-ordering',
  SHOWREEL_ORDERING: 'media-showreel-ordering',
};

export const ENTITY_ACTION_TYPES = {
  UPDATE_PRIVACY: 'update-entity-privacy',
  UPDATE_STATUS: 'update-entity-status',
  UPDATE_AGENCY_CONNECTION_STATUS: 'update-agency-connection-status',
};

export const ENTITY_DISPLAY_STATUS = {
  SHOW: 1,
  HIDE: 2,
  REJECTED: 3,
};

export const VALID_EDIT_DISPLAY_STATUS_FILTER = `${ENTITY_DISPLAY_STATUS.SHOW},${ENTITY_DISPLAY_STATUS.HIDE}`;

export const PAGINATION_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const VIDEO_ACTION_BUTTONS = {
  LIVESTREAM: 'livestream',
  LIVE_NOW: 'live_now',
  WATCH: 'watch',
  WATCH_NOW: 'watch_now',
  TRAILER: 'trailer',
  POSTER: 'poster',
  ADD: 'add',
  IMAGES: 'images_link',
  VIDEOS: 'link',
  POSTER_ON_HOVER: 'poster_on_hover',
  REVIEWS: 'reviews',
};

export const NOTIFICATION_NEW_ENTITY = {
  REVIEWS: `${TP}.FN_TAB_REVIEWS`,
  PHOTOS: `${TP}.FN_TAB_PHOTOS`,
  VIDEOS: `${TP}.FN_TAB_VIDEOS`,
  PRODUCTIONS: `${TP}.m_PROD`,
  AGENCIES: `${TP}.PRIVACY_AGENCY`,
};

export const ACKNOWLEDGE_ENTITY_NAME = {
  REVIEW: 'review',
  PHOTO: 'photo',
  VIDEO: 'video',
  PRODUCTION: 'production',
  AGENCY: 'agency',
};

export const MEDIA_SHOWREEL_OPTIONS = {
  INTRO: {
    id: 1,
    slug: 'intro-show-reel',
  },
  AUDITION: {
    id: 2,
    slug: 'audition-show-reel',
  },
  ORGANIZATION_INTERIOR_PHOTOS: {
    id: 7,
    slug: 'organization-interior-photos',
  },
  ORGANIZATION_EXTERIOR_PHOTOS: {
    id: 8,
    slug: 'organization-exterior-photos',
  },
};

export const MEDIA_SECTIONS = {
  INTRO_SHOWREEL: 'intro_showreel',
  AUDITION_SHOWREEL: 'audition_showreel',
  GENERAL_VIDEOS: 'general_videos',
  GENERAL_PHOTOS: 'general_photos',
  PART_OF_PRODUCTION: 'part_of_production',
  LIVESTREAM_VIDEOS: 'livestream_videos',
  PRIMARY_COVER_PHOTO: 'primary_cover_photo',
  PRIMARY_VIDEO_ABOUT: 'primary_video_about',
  ORGANIZATION_INTERIOR_PHOTOS: 'organization_interior_photos',
  ORGANIZATION_EXTERIOR_PHOTOS: 'organization_exterior_photos',
  FESTIVAL_INTERIOR_PHOTOS: 'festival_interior_photos',
  FESTIVAL_EXTERIOR_PHOTOS: 'festival_exterior_photos',
};

export const COMMON_VIDEO_ABOUT_OPTIONS = [
  {
    // key: `${TP}.FN_MARK_COVER_PHOTO_PRIMARY`,
    key: `Mark video about as primary on media widget`,
    value: MEDIA_SECTIONS.PRIMARY_VIDEO_ABOUT,
  },
];

export const ARTIST_PHOTO_SECTION_OPTIONS = [
  {
    key: `${TP}.FN_ADD_TO_PROFILE_PHOTOS_OPTION`,
    value: MEDIA_SECTIONS.GENERAL_PHOTOS,
  },
  {
    key: `${TP}.FN_ADD_TO_PRODUCTION_OPTION`,
    value: MEDIA_SECTIONS.PART_OF_PRODUCTION,
  },
];

export const ARTIST_VIDEO_SECTION_OPTIONS = [
  {
    key: `${TP}.FN_ADD_TO_INTROSHOWREEL_OPTION`,
    value: MEDIA_SECTIONS.INTRO_SHOWREEL,
  },
  {
    key: `${TP}.FN_ADD_TO_AUDITIONSHOWREEL_OPTION`,
    value: MEDIA_SECTIONS.AUDITION_SHOWREEL,
  },
  {
    key: `${TP}.FN_ADD_TO_PROFILE_VIDEOS_OPTION`,
    value: MEDIA_SECTIONS.GENERAL_VIDEOS,
  },
  {
    key: `${TP}.FN_ADD_TO_PRODUCTION_OPTION`,
    value: MEDIA_SECTIONS.PART_OF_PRODUCTION,
  },
];

export const ORGANIZATION_PHOTO_SECTION_OPTIONS = [
  {
    key: `${TP}.FN_ADD_TO_ORGANIZATION_PHOTOS_OPTION`,
    value: MEDIA_SECTIONS.GENERAL_PHOTOS,
  },
  {
    key: `${TP}.FN_ADD_TO_PRODUCTION_OPTION`,
    value: MEDIA_SECTIONS.PART_OF_PRODUCTION,
  },
];

export const ORGANIZATION_VIDEO_SECTION_OPTIONS = [
  {
    key: `${TP}.FN_ADD_TO_ORGANIZATION_VIDEOS_OPTION`,
    value: MEDIA_SECTIONS.GENERAL_VIDEOS,
  },
  {
    key: `${TP}.FN_ADD_TO_PRODUCTION_OPTION`,
    value: MEDIA_SECTIONS.PART_OF_PRODUCTION,
  },
];

export const FESTIVAL_PHOTO_SECTION_OPTIONS = [
  {
    key: `${TP}.FN_ADD_TO_FESTIVAL_PHOTOS_OPTION`,
    value: MEDIA_SECTIONS.GENERAL_PHOTOS,
  },
  {
    key: `${TP}.FN_ADD_TO_PRODUCTION_OPTION`,
    value: MEDIA_SECTIONS.PART_OF_PRODUCTION,
  },
];

export const FESTIVAL_VIDEO_SECTION_OPTIONS = [
  {
    key: `${TP}.FN_ADD_TO_FESTIVAL_VIDEOS_OPTION`,
    value: MEDIA_SECTIONS.GENERAL_VIDEOS,
  },
  {
    key: `${TP}.FN_ADD_TO_PRODUCTION_OPTION`,
    value: MEDIA_SECTIONS.PART_OF_PRODUCTION,
  },
];

export const REVIEW_SECTIONS = {
  GENERAL_REVIEWS: 'general_reviews',
  PART_OF_PRODUCTION: 'part_of_production',
};

export const PRODUCTION_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

export const EDIT_PAGE_TYPE = {
  ARTIST: 'artist',
  IMAGES: 'images',
  VIDEOS: 'videos',
  ORGANIZATIONS: 'organizations',
  ORGANIZATION: 'organization',

  COMPANY: 'company',
  FESTIVAL: 'festival',
};

export const RENTAL_OPTIONS = [
  {
    id: 1,
    key: `${TP}.FN_SETS`,
    value: 'sets',
  },
  {
    id: 2,
    key: `${TP}.FN_COSTUMES`,
    value: 'costume',
  },
  {
    id: 3,
    key: `${TP}.FN_SCORE`,
    value: 'score',
  },
];

export const SORT_TYPES = {
  YEAR_ASC: 'year',
};

export const RENTALS_SORT_ORDER = [
  {
    id: 'work_name',
    key: 'work_name',
    slug: 'work.name',
    name: `${TP}.FN_PRODUCTION_CARD_HEADER_WORK`,
  },
  {
    id: 'composer_name',
    key: 'composer_name',
    slug: 'workCreator.name',
    name: `${TP}.m_COMPOSER`,
  },
  {
    id: 'company_name',
    key: 'company_name',
    slug: 'company.name',
    name: `${TP}.m_COMPANY`,
  },
  {
    id: 'director_name',
    key: 'director_name',
    slug: 'director.name',
    name: `${TP}.m_PRODUCER`,
  },
  {
    id: 'conductor_name',
    key: 'conductor_name',
    slug: 'conductor.name',
    name: `${TP}.m_CONDUCTOR`,
  },
  {
    id: 'date_from',
    key: 'date_from',
    slug: 'created_at',
    name: `${TP}.m_DATE_FROM`,
  },
  {
    id: 'date_to',
    key: 'date_to',
    slug: 'updated_at',
    name: `${TP}.FN_DATE_TO`,
  },
  // {
  //   id: 'refresh_date',
  //   key: 'refresh_date',
  //   slug: 'refresh_date',
  //   name: `${TP}.m_REFRESH_DATE`,
  // },
];

export const SHARE_ACCESS_PLACES = {
  ARTIST_PAGE: 'artist_page',
  AGENCY_PAGE: 'agency_page',
  AGENCY_PAGE_ARTIST: 'agency_page_profile',
};

const env = process.env.APP_ENV;

export const SHARE_ACCESS_HUBSPOT_FORM_ID = {
  [SHARE_ACCESS_PLACES.ARTIST_PAGE]:
    env === 'production' ? '227b704d-972e-4bc5-bb00-d9f235ad47b4' : 'a026c561-b609-4225-b8af-76ee91c32fa7',
  [SHARE_ACCESS_PLACES.AGENCY_PAGE]:
    env === 'production' ? 'bfaebb25-c357-472d-9b1b-0047c1b8d05f' : '1aefff26-fd6e-4eef-bf65-49a98d75f679',
  [SHARE_ACCESS_PLACES.AGENCY_PAGE_ARTIST]:
    env === 'production' ? '4dae9931-7e4c-4890-bd61-23319aa4f85e' : 'd62432d6-4e7b-4fd2-8f3e-5d572ab58637',
};

export const HS_GENERAL_FORMS_IDS = {
  GIFT_SEND_FORM:
    env === 'production' ? 'e6a7bc76-2169-4c67-bed3-e7d48aa6415d' : '13ae29a4-d8c0-4f85-8411-a8aeaf70397c',
};

export const SUSPENSION_CATEGORY = {
  REGULAR: 'regular',
  ACQUISITION: 'acquisition',
  REBRANDING: 'rebranding',
};

export const PROFILE_TYPES = {
  1: 'artist',
  2: 'agency',
  3: 'company',
};

export const artistPageQueryList = ['letter', 'page'];

export const performancePageQueryList = [
  'date_from',
  'date_to',
  'added_by',
  'approved_by',
  'country_id',
  'city_id',
  'company_id',
  'composer_id',
  'work_id',
];

export const worksPageQueryList = ['composer_id', 'work_type_id'];

export const festivalsPageQueryList = ['years', 'year'];

export const competitionsPageQueryList = [
  'country_id',
  'city_id',
  'date',
  'age_limit_men',
  'age_limit_women',
  'status',
];

export const rentalsPageQueryList = ['work_id', 'individual_id', 'organization_id', 'sort'];

export const videosPageQueryList = ['composer_id', 'work_id', 'company_id', 'festival_id', 'profile_id'];

export const IN_VALID_ACTION_TYPES = {
  VALIDATION_PENDING_PRODUCTION: 'validation_pending_production',
  VALIDATION_REJECTED_PRODUCTION: 'validation_rejected_production',
  VALIDATION_PENDING_MEDIA: 'validation_pending_media',
  HIDDEN_PRODUCTION: 'hidden_production',
};

export const IN_VALID_ACTION_DATA = {
  [IN_VALID_ACTION_TYPES.VALIDATION_PENDING_PRODUCTION]: {
    HEADER: 'FN_WL_NON_VALIDATED_VALIDATION_PENDING_TITLE',
    TEXT: 'FN_INVALID_ACTION_MODAL_TEXT',
  },
  [IN_VALID_ACTION_TYPES.VALIDATION_REJECTED_PRODUCTION]: {
    HEADER: 'FN_WL_NON_VALIDATED_VALIDATION_REJECTED_TITLE',
    TEXT: 'FN_INVALID_ACTION_MODAL_REJECTED_TEXT',
  },
  [IN_VALID_ACTION_TYPES.VALIDATION_PENDING_MEDIA]: {
    HEADER: 'FN_WL_NON_VALIDATED_VALIDATION_PENDING_TITLE',
    TEXT: 'FN_INVALID_ACTION_MODAL_MEDIA_TEXT',
  },
  [IN_VALID_ACTION_TYPES.HIDDEN_PRODUCTION]: {
    HEADER: 'FN_INVALID_ACTION_MODAL_MEDIA_HIDDEN_PRODUCTION_HEADER',
    TEXT: 'FN_INVALID_ACTION_MODAL_MEDIA_HIDDEN_PRODUCTION_TEXT',
  },
};

export const AGENCY_ACTORS = {
  ARTIST: 'artist',
  AGENCY: 'agency',
  ORGANIZATION: 'organization',
};

export const keyCodes = {
  ESCAPE: {
    key: 'Escape',
    code: 27,
  },
};
export const ICON_TYPES = {
  SEARCH: 'search',
  INFO: 'info',
  WARNING: 'warning',
  RED_MASK: 'redMask',
};

export const PROFESSION_IDS = {
  COMPOSER: 5,
  LIBRETTIST: 16,
};

export const EDIT_FORM_HUBSPOT_ID =
  env === 'production' ? 'e82887e6-ed47-4b3f-b5fa-8e3a0e4db28d' : '0542dc5a-69ba-4eca-a0e3-f90d641207d1';

export const SHOW_HIDE_HUBSPOT_ID =
  env === 'production' ? 'f3320a2d-419f-4750-a3dc-b6c0b1807fd1' : '3c536646-f446-4d57-998e-ca9d3bd7b9e9';

export const RESEND_PASSCODE_HUBSPOT_ID =
  env === 'production' ? '006c81a4-5a4a-4d61-8cbb-7162d882138d' : 'b194f605-1f93-4199-bf6a-33a7bee26ddd';
export const ARTIST_IMPRESSION_TRACKING_EVENTS = {
  ARTIST_CARD_IMPRESSION: 'artistCardImpression',
  ARTIST_RECOMMENDATION_IMPRESSION: 'artistRecommendationImpression',
  CAST_LINK_IMPRESSION: 'castLinkImpression',
  CAST_AND_CREW_IMPRESSION: 'castAndCrewImpression',
  ARTIST_LISTING_FILTER_IMPRESSION: 'artistListingFilterImpression',
  CASTING_TOOL_ARTIST_IMPRESSION: 'castingToolArtistImpression',
};

export const COMPANY_IMPRESSION_TRACKING_EVENTS = {
  COMPANY_LISTING_IMPRESSION: 'companyListingImpression',
  COMPANY_SEARCH_FILTER_IMPRESSION: 'companySearchFilterImpression',
  COMPANY_SLUG_IMPRESSION: 'companySlugImpression',
  CASTING_TOOL_COMPANY_IMPRESSION: 'castingToolCompanyImpression',
};

export const FESTIVAL_IMPRESSION_TRACKING_EVENTS = {
  FESTIVAL_LISTING_IMPRESSION: 'festivalListingImpression',
  FESTIVAL_SEARCH_FILTER_IMPRESSION: 'festivalSearchFilterImpression',
  CASTING_TOOL_FESTIVAL_IMPRESSION: 'castingToolFestivalImpression',
  FESTIVAL_SLUG_IMPRESSION: 'festivalSlugImpression',
};

export const LSVOD_IMPRESSION_TRACKING_EVENTS = {
  LSVOD_CARD_IMPRESSION: 'lsVodCardImpression',
};

export const INSIGHTS_CLICK_TRACKING_EVENTS = {
  TICKET_CLICKED_EVENT: 'ticketClickedEvent',
  TAB_CLICKED_EVENT: 'tabClickedEvent',
  LSVOD_CARD_CLICKED_EVENT: 'lsvod_card_clicked_event',
};

export const RECOMMENDATION_IMPRESSION = 'RecommendationImpression';
export const STUBPAGE_IMPRESSION = 'DetailsPageViewImpression';

export const ENTITY_TYPE = {
  ARTIST: 'artist',
  COMPOSER: 'composer',
  PRODUCTION: 'production',
  WORK: 'work',
  VENUE: 'venue',
  PERFORMANCE: 'performance',
  ORGANIZATION: 'organization',
};

export const MEDIA_UPLOAD_INSTRUCTION_PAGE = 'https://help.operabase.com/knowledge/en/artist-photo-upload-guidelines';

export const ORGANIZATION_TYPES = {
  COMPANY: {
    id: 1,
    name: 'Company',
    slug: 'company',
  },
  FESTIVAL: {
    id: 2,
    name: 'Festival',
    slug: 'festival',
  },
  YAP: {
    id: 3,
    name: 'YAP',
    slug: 'yap',
  },
  ORCHESTRA: {
    id: 4,
    name: 'Orchestra',
    slug: 'orchestra',
  },
  CHORUS: {
    id: 5,
    name: 'Chorus',
    slug: 'chorus',
  },
  BALLET: {
    id: 6,
    name: 'Ballet',
    slug: 'bellet',
  },
  CHOIR: {
    id: 7,
    name: 'Choir',
    slug: 'choir',
  },
  OTHER: {
    id: 8,
    name: 'Other',
    slug: 'other',
  },
  CHAMBER_ENSEMBLE: {
    id: 17,
    name: 'Chamber Ensemble',
    slug: 'chamber-ensemble',
  },
  EXTRA: {
    id: 19,
    name: 'Extra',
    slug: 'extra',
  },
};

export const NEW_CONTACT_TYPES = [CONTACTS_NAMES.SNS, CONTACTS_NAMES.WEBSITE, CONTACTS_NAMES.OTHER];
export const RESTRICTED_COMPOSER_NAMES = ['various', 'Various'];
export const RESTRICTED_WORK_NAMES = [
  'concert',
  'Концерт',
  'Konzert',
  'Concerto',
  'Concert',
  'Koncert',
  'Concierto',
  'Concert',
];

export const REPERTOIRE_SECTIONS = {
  FUTURE_REPERTOIRE: 'future_repertoire',
  REPERTOIRE: 'repertoire',
};

export const ARTIST_AGENCY_SECTIONS = {
  YOUR_CONTACT_INFO: 'your_contact_info',
  AGENCY_REPRESENTATION_INFO: 'agency_representation_info',
};

export const DIGITAL_PRESENCE_SECTIONS = {
  SOCIAL_PRESENCE: 'digital_social_presense',
};

export const BIOGRAPHY_SECTIONS = {
  ADD_BIOGRAPHY: 'add_biography',
};

export const REVIEWS_SECTIONS = {
  GENERAL_REVIEW: 'general_review',
};

export const PERFORMANCE_SECTION = {
  ADD_PERFORMANCE: 'add_performance',
};

export const ARTIST_PROFILE_INFO_SECTIONS = {
  PROFESSION_ALIAS_INFO: 'profession_alias_info',
  COVER_PHOTO: 'cover_photo',
  PERFORMED_WITH_ARTIST: 'customise-artist',
  PERFORMED_WITH_ORG: 'customise-organization',
  PERFORMED_AT_VENUE: 'customise-venue',
  PROFILE_NAME: 'profile_name',
  PROFILE_PHOTO: 'profile_photo',
  UPLOAD_CV: 'upload_cv',
};

export const MANAGER_SECTION = {
  ADD_ANOTHER_CONTACT: 'add_another_contact',
};

export const DEFAULT_SCROLL_CONFIG = { offset: -150, smooth: true, duration: 250 };

export const CHIPS_FILTER_TYPE = {
  PRODUCTION_TYPE: 'productions-type',
  VIDEOS_TYPE: 'videos-type',
  REVIEWS_TYPE: 'reviews-type',
  PHOTOS_TYPE: 'photos-type',
};

export const CHIPS_FILTER_VALUES = {
  RED_MASK: 'redMask',
  GREY_MASK: 'greyMask',
  PENDING_ACTION: 'pendingAction',
  PUBLISHED: 'published',
  CHANGE_REQUEST: 'changeRequest',
  DRAFT: 'draft',
  NEW: 'new',
  MISSING_PROGRAMS: 'missingPrograms',
  UPCOMING_PRODUCTIONS: 'upcomingProductions',
  NEWLY_ADDED: 'newlyAdded',
};

export const PROGRAM_INFO_STATUS = {
  COMPLETE: {
    ID: 1,
  },
  INCOMPLETE_FUTURE: {
    ID: 2,
  },
  INCOMPLETE_PAST: {
    ID: 3,
  },
};

export const PRODUCTION_DATES = {
  FUTURE: 'FUTURE',
  PAST: 'PAST',
};

export const ADD_NEW_OPTION = 'ADD_NEW_OPTION';
