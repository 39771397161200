import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';

import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';

import { PROFILE_SWITCH } from 'constants/cookieConstants';
import { TP } from 'constants/consts';

import { useTranslation } from 'src/i18n';
import { getCookie, removeCookie } from 'utils/cookie';
import { getProfileName } from 'utils/profileDropdown';

import { updateSessionIdInCookie } from './tracking';

const UPDATE_SESSION_DEBOUNCE_TIME = 1000 * 60 * 5; // 5 minutes
export const DEFAULT_EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
];

export const trackUserActivityEvents = () => {
  if (typeof window !== 'undefined') {
    DEFAULT_EVENTS.forEach(e => {
      window.addEventListener(e, trackEvent);
    });
  }
};

const trackEvent = debounce(() => {
  updateSessionIdInCookie();
}, UPDATE_SESSION_DEBOUNCE_TIME);

export const useProfileSwitchSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { profileData } = useGetTargetProfile();

  useEffect(() => {
    const isProfileSwitch = getCookie(PROFILE_SWITCH);
    const profileName = getProfileName(profileData);
    if (isProfileSwitch && !!profileName) {
      enqueueSnackbar(`${t(`${TP}.NOW_VIEWING_AS`)} ${profileName}`);
      removeCookie(PROFILE_SWITCH);
    }
  }, [profileData, enqueueSnackbar, t]);
};
