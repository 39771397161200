import { getCampaignAttributionDetails } from 'utils/cookie';

import usePageContext from './usePageContext';

const useCampaignAttributionDetails = ({ onlyActive = false } = {}) => {
  const { utmParams, hasActiveUTMParams } = usePageContext();

  if (onlyActive && !hasActiveUTMParams) {
    return {};
  }

  if (!utmParams?.utmCampaign) {
    return getCampaignAttributionDetails();
  }

  return utmParams;
};

export default useCampaignAttributionDetails;
