const AUTH = 'auth';
const SOCIAL_LOGIN_URLS = `${AUTH}/oauth`;
const COMPANIES = 'companies';
const AGENCIES = 'agencies';
const CONVERSATIONS = 'conversations';

const COUNTRIES_ENDPOINT = 'countries';
const COUNTRY_CITY_ENDPOINT = countryId => `${COUNTRIES_ENDPOINT}/${countryId}/cities`;
const CITY_ENDPOINT = `${COUNTRIES_ENDPOINT}/cities`;
const CURRENCIES = 'currencies';
const FILTER_AGGREGATION_SEARCH = endpoint => `${endpoint}/aggregations`;
const PERFORMANCES = 'performances';
const USER_PERFORMANCES = 'users/performances';
const PRODUCTIONS = 'productions';
const CHANGE_REQUEST = 'change_requests';
const PRODUCTIONS_REVIVALS = 'productions/revivals';
const PRODUCTIONS_AGGREGATIONS = 'productions/aggregations';
const COMPETITIONS = 'competitions';
const REPERTOIRE = 'repertoire';
const PROFILES_ENDPOINT = 'profiles';
const PROFILE_CONTACTS_ENDPOINT = profileId => `${PROFILES_ENDPOINT}/${profileId}/contacts`;
const ARTIST_TRANSLATIONS = profileId => `${PROFILES_ENDPOINT}/${profileId}/${TRANSLATIONS}`;

const WORKS_AGGREGATIONS = 'works/aggregations';
const PAGERULES_ENDPOINT = 'pagerules';
const PROFILES_BIO_ENDPOINT = profileId => `${PROFILES_ENDPOINT}/${profileId}/descriptions`;
const NEW_PRODUCTION_DOCUMENT_BY_ID = (productionId, documentId) =>
  `${PRODUCTIONS}/${productionId}/documents/${documentId}`;
const PRODUCTION_TO_PRODUCTION_WORK_GENRES = (productionId, workId) =>
  `${PRODUCTIONS}/${productionId}/works/${workId}/workTypes`;
const ARTISTS_WORKED_WITH = userId => `users/${userId}/referrals`;
const GIFT_SUBSCRIPTIONS = userId => `users/${userId}/referralStatus`;
const ORGANIZATION_TRANSLATIONS = orgId => `${ORGANIZATION}/${orgId}/${TRANSLATIONS}`;

const MUSICAL_WORK_ENDPOINT = 'works';
const MUSICAL_WORK_TYPES_ENDPOINT = `${MUSICAL_WORK_ENDPOINT}/types`;
const WORK_STAGING_TYPES_ENDPOINT = `${MUSICAL_WORK_ENDPOINT}/stagingTypes`;
const MUSICAL_WORK_STYLES_ENDPOINT = `${MUSICAL_WORK_ENDPOINT}/styles`;
const VENUES = 'venues';
const ORGANIZATIONS = 'organizations';
const CONTACTS = 'contacts';
const ORGANIZATION_STAGES_ENDPOINT = orgId => `${ORGANIZATIONS}/${orgId}/stages`;
const ADDRESSES = 'addresses';
const FESTIVALS = 'festivals';

const ARTISTS = 'artists';
const VIDEOS = 'proxy/videos';
const ARTICLES = 'articles';
const COMPOSER = 'works/composers';
const REVIEWS_ENDPOINT = 'reviews';
const WORK_ROLES = 'works/roles';
const AGENTS = 'agents';
const RENTALS = 'rentals';
const CONNECTIONS = 'connections';
const ACCOUNT_SETTINGS = userId => `users/${userId}/settings`;
const SUBSCRIPTIONS = 'subscriptions';
const REGISTRATION = userId => `users/${userId}/registrations`;
const ADVANCED_STATS = 'stats/advanced';
const ADVANCED_STATS_AGGREGATIONS = 'stats/advanced/aggregations';
const USER_SUBSCRIPTIONS = userId => `users/${userId}/subscriptions`;
const PRODUCTION_CASTS = productionId => `productions/${productionId}/contributions`;

const AGENCY = id => `${AGENCIES}/${id}`;
const AGENCY_ARTISTS = id => `${AGENCIES}/${id}/${ARTISTS}`;

const EMPLOYEES = 'employees';
const TRANSLATIONS = 'translations';
const STAGES = 'stages';
const ORGANIZATION_RELATIONS = orgId => `${ORGANIZATION}/${orgId}/${RELATIONS}`;
const RENTAL_ACTION = prodId => `${PRODUCTIONS}/${prodId}/${RENTALS}`;
const RENTAL_INCLUDES = (prodId, rentalId) => `${PRODUCTIONS}/${prodId}/${RENTALS}/${rentalId}`;

const PROFILE_ACCESSES = `${PROFILES_ENDPOINT}/profileaccesses`;
const PROFILE_ACCESS_REQUESTS = `${PROFILES_ENDPOINT}/profileaccessrequests`;

const PROFILE_REPERTOIRE = profileId => `${PROFILES_ENDPOINT}/${profileId}/${REPERTOIRE}`;
const MEDIA = 'media';
const MEDIA_SHOWREELS = 'media/showreelsmedia';
const MEDIA_SHOWREELS_ORDER = 'media/showreelsmedia/order';
const PRODUCTION_MEDIA = id => `productions/${id}/media`;
const PRODUCTION_REVIEWS_V1 = `api/reviews`;
const PRODUCTION_REVIEWS_V2 = productionId => `api/productions/${productionId}/reviews`;
const MEDIA_ATTRIBUTES = 'media/attributes';
const MEDIA_TAGS = 'media/tags';
const MEDIA_ACTIONS = mediaId => `media/${mediaId}/actions`;
const MEDIA_ORDER = `${MEDIA}/order`;
const REVIEWS = profileId => `${PROFILES_ENDPOINT}/${profileId}/reviews`;
const PRODUCTION_REVIEWS = reviewId => `reviews/${reviewId}`;
const NEW_REVIEWS = 'reviews';
const REVIEW_ACTIONS = reviewId => `${NEW_REVIEWS}/${reviewId}/actions`;
const REVIEW_ORDER = `${NEW_REVIEWS}/order`;

const SEASONS = 'seasons';
const PROFESSIONS = 'professions';
const PROFILE_PROFESSION = profileId => `${PROFILES_ENDPOINT}/${profileId}/${PROFESSIONS}`;
const REGIONS = 'regions';
const PRIVACY_OPTIONS = 'privacy_attributes';
const PRODUCTION_DOCUMENTS = `${PRODUCTIONS}/documents`;
const PRODUCTION_DOCUMENTS_V1 = id => `api/productions/${id}/documents`;
const PRODUCTION_PRIVACY = productionId => `${PRODUCTIONS}/${productionId}/privacy`;

const PRODUCTION_DATES = productionId => `${PRODUCTIONS}/${productionId}/productionPerformances`;
const PRODUCTION_DATE_ACTIONS = productionId => `${PRODUCTIONS}/${productionId}/actions`;
const PRODUCTION_CHNAGE_REQUEST_ACTIONS = productionId => `${CHANGE_REQUEST}/${productionId}/actions`;
const PRODUCTION_DOCUMENTS_BY_ID = productionId => `${PRODUCTIONS}/${productionId}/documents`;
const PRODUCTION_ACTIONS = productionId => `${PRODUCTIONS}/${productionId}/actions`;
const PRODUCTION_SYNOPSIS = productionId => `${PRODUCTIONS}/${productionId}/synopsis`;
const PRODUCTION_SYNOPSIS_BY_ID = (productionId, synopsisId) => `${PRODUCTIONS}/${productionId}/synopsis/${synopsisId}`;
const PRODUCTION_ENSEMBLES = productionId => `${PRODUCTIONS}/${productionId}/contributions`;
const PRODUCTION_TICKETS = productionId => `${PRODUCTIONS}/${productionId}/tickets`;
const ENSEMBLES = 'ensembles';
const PRODUCTION_WORKS = productionId => `${PRODUCTIONS}/${productionId}/works`;
const PRODUCTION_WORK_TYPES = id => `${PRODUCTIONS}/${id}/workTypes`;
const PRODUCTION_TAG_TYPES = `${PRODUCTIONS}/productionTags`;
const PRODUCTION_TO_TAG_TYPES = id => `${PRODUCTIONS}/${id}/productionToTags`;

const SUPPORT_REQUESTS = 'users/supportRequests';

const USER_REWARD_BALANCE = 'users/rewardBalance';
const USER_REFERRALS = `users/giftTokens`;

const ORGANIZATION = 'organizations';
const RELATIONS = 'relations';
const ORGANIZATION_CONTACTS = orgId => `${ORGANIZATION}/${orgId}/${CONTACTS}`;
const ORGANIZATION_ADDRESSES = orgId => `${ORGANIZATION}/${orgId}/${ADDRESSES}`;
const ORGANIZATION_EMPLOYEES = orgId => `${ORGANIZATION}/${orgId}/${EMPLOYEES}`;
const ORGANIZATION_CERTAIN_EMPLOYEE = (orgIdOrParams, empId) => {
  const orgId = typeof orgIdOrParams === 'object' ? orgIdOrParams?.orgId : orgIdOrParams;
  const employeeId = empId || orgIdOrParams?.empId;
  return employeeId ? `${ORGANIZATION}/${orgId}/${EMPLOYEES}/${employeeId}` : `${ORGANIZATION}/${orgId}/${EMPLOYEES}`;
};
const ORGANIZATION_ACTIONS = orgId => `${ORGANIZATION}/${orgId}/actions`;
const ORGANIZATION_TYPES = `${ORGANIZATION}/types`;
const ORGANIZATION_STAGES = orgId => `${ORGANIZATION}/${orgId}/${STAGES}`;

module.exports = {
  ACCOUNT_SETTINGS,
  ADVANCED_STATS,
  ADVANCED_STATS_AGGREGATIONS,
  AGENCIES,
  AGENCY,
  AGENCY_ARTISTS,
  AGENTS,
  ARTICLES,
  ARTIST_TRANSLATIONS,
  ARTISTS,
  SOCIAL_LOGIN_URLS,
  CITY_ENDPOINT,
  COMPANIES,
  COMPETITIONS,
  COMPOSER,
  CONNECTIONS,
  CONVERSATIONS,
  COUNTRIES_ENDPOINT,
  COUNTRY_CITY_ENDPOINT,
  CURRENCIES,
  EMPLOYEES,
  FESTIVALS,
  FILTER_AGGREGATION_SEARCH,
  PERFORMANCES,
  USER_PERFORMANCES,
  PRODUCTIONS,
  CHANGE_REQUEST,
  PRODUCTIONS_REVIVALS,
  REPERTOIRE,
  PROFILES_ENDPOINT,
  PROFILE_ACCESSES,
  PROFILE_ACCESS_REQUESTS,
  MEDIA,
  MEDIA_ATTRIBUTES,
  MEDIA_TAGS,
  MUSICAL_WORK_ENDPOINT,
  MUSICAL_WORK_STYLES_ENDPOINT,
  MUSICAL_WORK_TYPES_ENDPOINT,
  SUBSCRIPTIONS,
  USER_SUBSCRIPTIONS,
  PROFILE_REPERTOIRE,
  REVIEWS,
  PRODUCTION_REVIEWS,
  PRODUCTION_REVIEWS_V1,
  PRODUCTION_REVIEWS_V2,
  NEW_REVIEWS,
  REVIEW_ACTIONS,
  SEASONS,
  PROFESSIONS,
  REGIONS,
  PRIVACY_OPTIONS,
  PRODUCTION_ACTIONS,
  PRODUCTION_CASTS,
  PRODUCTION_DATES,
  PRODUCTION_DATE_ACTIONS,
  PRODUCTION_DOCUMENTS,
  PRODUCTION_DOCUMENTS_BY_ID,
  PRODUCTION_DOCUMENTS_V1,
  MEDIA_ACTIONS,
  PRODUCTION_PRIVACY,
  PRODUCTIONS_AGGREGATIONS,
  PROFILE_CONTACTS_ENDPOINT,
  PROFILE_PROFESSION,
  REGISTRATION,
  RENTALS,
  REVIEWS_ENDPOINT,
  SUPPORT_REQUESTS,
  PRODUCTION_SYNOPSIS,
  PRODUCTION_ENSEMBLES,
  PRODUCTION_SYNOPSIS_BY_ID,
  PRODUCTION_TICKETS,
  PRODUCTION_WORKS,
  PRODUCTION_WORK_TYPES,
  WORK_STAGING_TYPES_ENDPOINT,
  PRODUCTION_TAG_TYPES,
  PRODUCTION_TO_TAG_TYPES,
  VENUES,
  ORGANIZATION_STAGES_ENDPOINT,
  WORK_ROLES,
  VIDEOS,
  ENSEMBLES,
  PRODUCTION_MEDIA,
  PRODUCTION_CHNAGE_REQUEST_ACTIONS,
  MEDIA_SHOWREELS,
  MEDIA_SHOWREELS_ORDER,
  REVIEW_ORDER,
  MEDIA_ORDER,
  RENTAL_ACTION,
  RENTAL_INCLUDES,
  USER_REFERRALS,
  USER_REWARD_BALANCE,
  ORGANIZATION,
  ORGANIZATIONS,
  ORGANIZATION_RELATIONS,
  ORGANIZATION_CONTACTS,
  ORGANIZATION_EMPLOYEES,
  ORGANIZATION_CERTAIN_EMPLOYEE,
  ORGANIZATION_ACTIONS,
  ORGANIZATION_TYPES,
  ORGANIZATION_ADDRESSES,
  ORGANIZATION_STAGES,
  ADDRESSES,
  CONTACTS,
  ARTISTS_WORKED_WITH,
  GIFT_SUBSCRIPTIONS,
  NEW_PRODUCTION_DOCUMENT_BY_ID,
  ORGANIZATION_TRANSLATIONS,
  PAGERULES_ENDPOINT,
  PRODUCTION_TO_PRODUCTION_WORK_GENRES,
  PROFILES_BIO_ENDPOINT,
  WORKS_AGGREGATIONS,
};
