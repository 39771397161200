import { getList } from 'utils/API';
import { formatResponseToList } from 'utils/crudUtils';
import { getOrderedReviewList } from 'utils/common';

import { ENTITY_DISPLAY_STATUS, ENTITY_TYPE } from 'constants/index';
import { getQuery } from 'utils/globals/queries';

const ENDPOINTS = {
  STATIC: {
    REVIEWS: 'reviews',
  },
  DYNAMIC: {},
};

const fetchReviews = (params, cookies) =>
  getList(ENDPOINTS.STATIC.REVIEWS, params, cookies).then(response => formatResponseToList(response));

const fetchFeaturedReviews = ({ entityType, entityId, page = 1, limit = 8, filters = {}, asEdit = false }, cookies) => {
  const REVIEW_ENTITY_TYPE_MAP = {
    [ENTITY_TYPE.ARTIST]: { value: 6, name: 'profiles' },
    [ENTITY_TYPE.ORGANIZATION]: { value: 13, name: 'organizations' },
    [ENTITY_TYPE.PRODUCTION]: { value: 3, name: 'productions' },
  };

  const viewingEntityType = REVIEW_ENTITY_TYPE_MAP[entityType];

  const params = {
    queryParams: {
      viewing_entity_type: viewingEntityType?.value,
      viewing_entity_id: entityId,
      ...filters,
      ...(asEdit && { as_edit: true, display_status: [ENTITY_DISPLAY_STATUS.HIDE, ENTITY_DISPLAY_STATUS.SHOW] }),
    },
    pagination: {
      page,
      limit,
    },
  };

  return fetchReviews(params, cookies).then(response => {
    const orderedReviews = getOrderedReviewList(response?.data, viewingEntityType?.name, entityId);

    return {
      ...response,
      data: orderedReviews,
    };
  });
};

const fetchProductionReviews = ({ entityType, entityId, page = 1, limit = 8, filters = {} }, cookies) => {
  const REVIEW_ENTITY_TYPE_MAP = {
    [ENTITY_TYPE.ARTIST]: { value: 14, name: 'productionContributions' },
    [ENTITY_TYPE.ORGANIZATION]: { value: 14, name: 'productionContributions' },
    [ENTITY_TYPE.PRODUCTION]: { value: 3, name: 'productions' },
  };

  const REVIEW_ENTITY_RELATION_MAP = {
    [ENTITY_TYPE.ARTIST]: 'cast',
    [ENTITY_TYPE.ORGANIZATION]: 'organization',
  };

  const viewingEntityType = REVIEW_ENTITY_TYPE_MAP[entityType];

  const params = {
    queryParams: {
      viewing_entity_type: viewingEntityType?.value,
      viewing_entity_id: entityId,
      review_entity_relation: REVIEW_ENTITY_RELATION_MAP[entityType],
      ...filters,
    },
    pagination: {
      page,
      limit,
    },
  };

  return fetchReviews(params, cookies).then(response => {
    const orderedReviews = getOrderedReviewList(response?.data, viewingEntityType?.name, entityId);

    return {
      ...response,
      data: orderedReviews,
    };
  });
};

const queries = {
  getFeaturedReviews: getQuery('GET_FEATURED_REVIEWS', fetchFeaturedReviews),
  getProductionReviews: getQuery('GET_PRODUCTION_REVIEWS', fetchProductionReviews),
};

export default queries;
